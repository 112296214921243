import styled from "styled-components";

export const Button = styled.button`
  background-color: white;
  border: none;
  color: #00acc1;
  cursor: pointer;
  vertical-align: middle;

.img {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
}
`;