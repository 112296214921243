import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import relatorio_img from "../../assets/img/relatorio.png";
import img_contasareceber from "../../assets/img/contasareceber.png";
import { Container } from "./styles.js";
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  cardGrid: {
    flexGrow: 1,
    paddingTop: 16,
  },
  card: {
    flex: 1,
    height: "100%",
    backgroundColor: "red",
  },
  cardActionArea: {
    flex: 1,
    height: "100%",
    backgroundColor: "green",
  },
  cardMedia: {
    height: "120",
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flex: 1,
    backgroundColor: "blue",
  },
  cardContentTitle: {
    flex: 1,
    backgroundColor: "blue",
  },
  cardContentRating: {
    backgroundColor: "blue",
  },
  cardContentDistance: {
    backgroundColor: "blue",
  },
}));

export default function Relatorios() {
  const classes = useStyles();

  const reports = [
    {
      id: "1",
      path: "/admin/EGR1000",
      name: "Contas a receber",
      desc: "Relatório de contas a receber",
      img: relatorio_img,

    },
    
    {
      id: "3",
      path: "/admin/EIR6000",
      name: "Ranking de vendas",
      desc: "Ranking de vendas",
      img: relatorio_img,
    },
    {
      id: "4",
      path: "/admin/PRONTAENTREGA",
      name: "Pronta Entrega",
      desc: "Pronta Entrega",
      img: relatorio_img,
    },
    {
      id: "5",
      path: "/admin/PROGRAMACAO",
      name: "Programação",
      desc: "Programação",
      img: relatorio_img,
    },
    {
      id: "6",
      path: "/admin/VENDAANALITICO",
      name: "Venda Analítico",
      desc: "Relatório de Vendas Analítico",
      img: relatorio_img,
    },
    {
      id: "7",
      path: "/admin/VENDASINTETICO",
      name: "Venda Sintético",
      desc: "Relatório de Vendas Sintético",
      img: relatorio_img,
    },
    {
      id: "8",
      path: "/admin/ESPECPRODUTOS",
      name: "Ficha técnica",
      desc: "Especificação do produto",
      img: relatorio_img,
    },
    {
      id: "9",
      path: "/admin/ESTOQUEIMAGEM",
      name: "Estoque Imagem",
      desc: "Relatório de Estoque por imagem",
      img: relatorio_img,
    },
  ];

  const handleCardClick = async (data) => {
 
  };
  return (
    <>
      <Container className={classes.cardGrid} maxWidth="xl">
        <Grid
          container
          direction="row"
          justify="start"
          alignItems="stretch"
          spacing={2}
        >
          {reports.map((data) => (
            <Grid
              item
              key={`GridItem-${data.id}`}
              xs={12}
              sm={3}
              md={3}
              lg={3}
              xl={3}
            >
              <Link to={data.path}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={data.name}
                      height="240"
                      image={data.img}
                      title={data.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {data.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {data.desc}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      Acessar
                    </Button>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
