/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DataTable from 'components/Table/Table.js';
import ItemProdutos from 'components/Pedidos/ModalItemProdutos';
import { withStyles } from '@material-ui/core/styles';

import IconRequestPass from '@material-ui/icons/CheckCircle';
import IconRequestfailure from '@material-ui/icons/Cancel';

import { ToastContainer, toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InfoClient from 'components/Clientes/InfoClientes/index';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import { ReactMultiEmail } from 'react-multi-email';

import Badge from '@material-ui/core/Badge';

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import ParcelNumber from 'components/Pedidos/RequestParcelNumber';
import { API } from '../../config/api';
import history from '../../services/history';
import Async from 'react-select/async';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import debounce from 'debounce-promise';
import {
  Pesquisa,
  Form,
  ButtonStyled,
  ButtonRequest,
  Input,
  FormAuto,
  PaperStyle,
  StyledTableCell,
  StyledTableRow,
  useStyles,
  StyledTableCellPrimary
} from './stylesFinaliza';

import axios from 'axios';
import moment from 'moment';
import Pdf from '../../components/Pedidos/PDF';
import { createBinary } from 'typescript';

// Função para criar OBJ dos Pedidos
function createRequest(
  view,
  busines,
  prioridade,
  idReq,
  PrazoPag,
  PrazoMed,
  VlParc,
  QtdParc,
  dateProg,
  total,
  idOperator,
  idCarriers,
) {
  return {
    view,
    busines,
    prioridade,
    idReq,
    PrazoPag,
    PrazoMed,
    VlParc,
    QtdParc,
    dateProg,
    total,
    idOperator,
    idCarriers,
  };
}

const headRequest = [
  {
    title: '',
    field: 'view',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      padding: 1,
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },
  {
    title: 'Empresa',
    field: 'busines',
  },
  {
    title: 'Prioridade',
    field: 'prioridade',
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },
  {
    title: 'Referência',
    field: 'idReq',
    headerStyle: {
      width: 100,
      maxWidth: 10,
      padding: 1,
    },
    cellStyle: {
      fontSize: '10px',
      minWidth: '100px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },
  {
    title: 'Prazo de pagamento',
    field: 'PrazoPag',
    headerStyle: {
      width: 100,
      maxWidth: 10,
      padding: 1,
    },
    cellStyle: {
      fontSize: '10px',
      minWidth: '200px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },
  {
    title: 'Prazo médio',
    field: 'PrazoMed',
  },
  {
    title: 'Vl. parcelas',
    field: 'VlParc',
  },
  {
    title: 'Qtd. parcelas',
    field: 'QtdParc',
  },
  {
    title: 'Programação',
    field: 'dateProg',
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      padding: '1px',
    },
  },

  {
    title: 'Total',
    field: 'total',
    headerStyle: {
      textAlign: 'right',
      width: '400px',
      maxWidth: '200px',
    },
    cellStyle: {
      fontSize: '10px',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      width: '95px',
      maxWidth: '95px',
      right: '30px',
    },
  },
];
function ccyFormat(numero) {
  return (
    'R$ ' +
    numero
      .toFixed(4)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')
  );
}

// Funções para criar o OBJ FinishRequest
function createDataFinishRequest(
  mailsent,
  icon,
  items,
  pdf,
  numeroSistema,
  numeroReferencia,
  prz_pag,
  programacao,
  cliente,
  status,
) {
  return {
    mailsent,
    icon,
    items,
    pdf,
    numeroSistema,
    numeroReferencia,
    prz_pag,
    programacao,
    cliente,
    status,
  };
}

const rowHeadRequest = [
  {
    title: '',
    field: 'mailsent',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'Status',
    field: 'icon',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: '',
    field: 'items',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Pdf Pedido',
    field: 'pdf',
    cellStyle: {
      fontSize: '12px',
    },
  },

  {
    title: 'N.Pedido',
    field: 'numeroSistema',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Referência',
    field: 'numeroReferencia',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Prazo Pagamento',
    field: 'prz_pag',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Programação',
    field: 'programacao',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Cliente',
    field: 'cliente',
    cellStyle: {
      fontSize: '12px',
    },
  },
  {
    title: 'Status',
    field: 'status',
    cellStyle: {
      fontSize: '12px',
    },
  },
];

function FinalizaPedido({ itemCart, Transp, Clients }) {
  const [isConfirmReq, setisConfirmReq] = useState(true);
  const [isFinishRequest, setIsFinishRequest] = useState(false);
  const [isViewRequest, setIsViewRequest] = useState(false);

  const [lista, setLista] = useState([]);
  const [listCart, setListCart] = useState([itemCart]);
  const [loading, setLloading] = useState(true);
  const [valueIp, setValue] = useState('');

  const [progress, SetProgress] = useState(false);
  const [itemSendRequest, setItemSendRequest] = useState([]);

  const [autoCliente, setAutoCliente] = useState([]);
  const [autoVendedor, setAutoVendedor] = useState([]);
  const [autoTransp, setTransp] = useState('');

  const [listRequest, setListRequest] = useState([]);
  const [dataParent, setDataParent] = useState('');

  const [nomeCliente, setNomeCliente] = useState('');
  const [nomeClienteForm, setNomeClienteForm] = useState('');

  const [nomeTransp, setNomeTransp] = useState('');
  const [nomeTranspForm, setNomeTranspForm] = useState('');

  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('');
  const [nomeTranspRedespachoForm, setNomeTranspRedespachoForm] = useState('');

  const [idCliente, setIdCliente] = useState('');
  const [idClienteForm, setIdClienteForm] = useState('');

  const [vendedorP, setVendedorP] = useState('');
  const [idVendedorP, setIdVendedorP] = useState('');
  const [idTransp, setIdTransp] = useState('');
  const [idTranspRedespacho, setIdTranspRedespacho] = useState('');
  const [obs, setObs] = useState('');
  const [prz_pag, setPrzPag] = useState('');
  const [vlparcelas, setVlParcelas] = useState('0');
  const [qtdparcelas, setQtdParcelas] = useState('1');
  const [prz_medio, setPrzMedio] = useState('0');
  const [vl_tot_pedido, setVl_Tot_Pedido] = useState(0);
  const [vl_tot_pedido_programacao, setVl_Tot_PedidoProgramacao] = useState(0);
  const [vl_tot_pedido_pe, setVl_Tot_PedidoPE] = useState(0);
  const [vl_tot_pedido_esp, setVl_Tot_PedidoESP] = useState(0);
  const [prz, setPrz] = useState('');
  const [prioridades, setPrioridade] = useState([
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
  ]);
  const [prioridadeEscolhida, setPrioridadeEscolhida] = useState('');
  const [fieldsReadonly, setfieldsReadonly] = useState(false);
  const [fieldsTranspReadonly, setfieldsTranspReadonly] = useState(false);
  const [fieldsRedespReadonly, setfieldsRedespReadonly] = useState(false);
  const [firstload, setFirstload] = useState(true);
  const [frete, setFrete] = useState('');
  const [CarteiraId, setCarteiraId] = useState(0);

  const [emails, setEmail] = useState([]);
  const [emailsAutomatico, setEmailAutomatico] = useState([]);

  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const userId = sessionStorage.getItem('id');
  const userIdErp = sessionStorage.getItem('id_erp');
  const clientes = sessionStorage.getItem('clientes');

  const isRequest = listCart.length === 0;

  const [vendedorNomeForm, setVendedorNomeForm] = useState('');
  const [idVendedorForm, setIdVendedorForm] = useState('');

  const [listRegrasAtribuidas, setlistRegrasAtribuidas] = useState([]);
  const [przPagamento, setprzPagamento] = useState([]);
  const [przPagamentoEscolhido, setprzPagamentoEscolhido] = useState('');

  const [prz_pag_readonly, setPrz_pag_readonly] = useState('');
  const [prz_pagCliente, setPrzPagCliente] = useState('');
  const [validacao, setValidacao] = useState('');
  const [regrasValidacao, setRegrasValidacao] = useState([]);

  const [indexTable, setIndexTable] = useState();
  const [erp_alerta, setErp_alerta] = useState('');
  const [referenc, setReferenc] = useState('');
  const [changeField, setchangeField] = useState('');
  //const [alertasRegras,setalertasRegras] = useState("");
  const [defaultSellerID, setDefaultSellerID] = useState('');
  const [defaultSeller, setDefaultSeller] = useState('');
  const [statusClient, setStatusClient] = useState('');
  const [perfilSuperUser, setPerfilSuperUser] = useState(false);



  const classes = useStyles();
  const perfil = sessionStorage.getItem('perfil');
  const id_erp = sessionStorage.getItem('id_erp');

  const [clienteEscolhido, setClienteEscolhido] = useState('');

  const calculateAverage = (inputString) => {
    const numbers = inputString.split('/').map(Number);
    const sum = numbers.reduce((acc, num) => acc + num, 0);
    const average = sum / numbers.length;
    return average;
  };



  /*
Regras aplicadas 03/09/2023
    Regra 5 - Super user poderá tirar qualquer pedido, sem nenhum bloqueio ou regras.

Regras aplicadas 23/01/2022
    Regra 1 - não permitir fazer pedido para clientes sem "prioridade" informada (disparar alerta - Cliente sem prioridade informada, ajustar o cadastro)
    Regra 2 - não permitir fazer pedido para clientes sem "prazo de pagamento" informado (disparar alerta - Cliente sem prazo de pagamento informado, ajustar o cadastro)
    Regra 3 - pedidos desmembrados devem ter o mesmo número de referência (permitir edição apenas no primeiro)
    Regra 4 - adicionar o e-mail pedido_portal_web@general-textile.com na lista de e-mails enviados
*/

  useEffect(() => {
    const loadSellers = async (inputValue, callback) => {
      try {
        const response = await axios.get(`${API.vendedores}?email=${email}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const data = response.data.data.map(item => {
          return { value: item.NUMCAD1, label: item.NOME.toUpperCase() };
        });

        setAutoVendedor(data);
      } catch (err) {
        //              toast.error("Não encontrado");
        setLloading(false);
      }
    };

    const loadRules = async () => {
      try {
        const response = await axios.get(
          `${API.regrasatribuidas}?email=${email}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        const lista = response.data.data;
        var objeto_regras = {};
        lista.map(item => {
          objeto_regras[`${item.REGRAS_ATRIBUIDAS_REGRAS_COMERCIAIS_NOME}`] =
            item.REGRAS_ATRIBUIDAS_VALOR;
        });
        objeto_regras[`prazo_pagamento_cliente`] = 0;
        objeto_regras[`prazo_medio_cliente`] = 0;
      

        //Aplicação da regra de SUPERUSER  - Caso o ID_ERP estaja listado no módulo regras comercias/sem_regra terá privilégios
        const arraySuperUsers = objeto_regras.sem_regras?objeto_regras.sem_regras.split(","):[];
        // Verificar se o ID procurado está presente no array
        if (arraySuperUsers.includes(id_erp)) {
          setPerfilSuperUser(true);
        } 

        setlistRegrasAtribuidas(objeto_regras);
      } catch (error) { }
    };

    async function loadPrazoPag() {
      try {
        const response_prz = await axios.get(`${API.getprzpag}`, {
          headers: {
            'x-access-token': token,
          },
        });
        //const opcoes_prz2 = response_prz.data.data.map(item => console.log(item));
        const opcoes_prz = response_prz.data.data.map(item => {
          return { value: item.DESCRICAO, label: item.DESCRICAO };
        });
        //  //console.log(opcoes_prz);
        setprzPagamento(opcoes_prz);

        // setStatusPedidos(status);
      } catch (error) {
        if (error.response && error.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar login');
          sessionStorage.clear();
        } else {
          toast.error('Erro ao carregar ');
        }
      }
    }

    const dataTransp = Transp
      ? Transp.map(item => {
        return { value: item.id, label: item.name.toUpperCase() };
      })
      : '';

    setTransp(dataTransp);

    loadPrazoPag();
    loadSellers();
    loadRules();
    confirmRequest();
  }, []);

  useEffect(() => {
    //   if(vl_tot_pedido_programacao> 500){
    const loadCreditClient = async () => {
      try {
        const response = await axios.get(
          `${API.infocomerciais}?conta_cliente=${idCliente}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        const dadoscomerciais = response.data.data;
        let credito = dadoscomerciais[0].IC_RESERVA_DISPONIVEL;
        let credito_pe = dadoscomerciais[0].IC_CREDITO_DISPONIVEL;
        var status = '';
        //        console.log("credito" + credito);
        if (vl_tot_pedido_programacao > credito && !perfilSuperUser) {
          status =
            '<br><span>***O cliente escolhido não possui crédito disponivel para aquisições do tipo PRG</span>';
          setfieldsRedespReadonly(false);
          setfieldsTranspReadonly(false);
        }
        if (vl_tot_pedido_pe > credito_pe && !perfilSuperUser) {
          status =
            status +
            '<br><span>***O cliente escolhido não possui crédito disponivel para aquisições do tipo P.E</span>';
        }
        setStatusClient(status);
        setDataParent(credito);
      } catch (err) { }
    };
    if (idCliente > 0) {
      loadCreditClient();
    }
    // }
  }, [idCliente]);

  useEffect(() => {
    const getPrazoPagamento = async () => {
      try {
        setFirstload(false);

        const total = Number(
          listCart[indexTable].total
            .replace('R$', '')
            .trim()
            .replaceAll('.', '')
            .replace(',', '.'),
        );
        setValidacao(
          validacao.map(item =>
            item.index === indexTable ? { ...item, validacao: 0 } : item,
          ),
        );

        //itemSendRequest.map((itemRequest, idx) => {
        itemSendRequest.forEach(async (itemRequest, idx) => {
          const rowIndex = idx;
          if (rowIndex === indexTable) {
            //console.log(rowIndex + indexTable);
            const response = await axios.get(
              `${API.prazomedio}?consulta=${itemRequest.prazo_pagamento}`,
              {
                headers: {
                  'x-access-token': token,
                },
              },
            );
console.log(response)
            let vlparc =
              response.data.data[0].QTD_PARCELAS > 0
                ? total / response.data.data[0].QTD_PARCELAS
                : 0;

            setVlParcelas(
              response.data.data[0].QTD_PARCELAS > 0
                ? vlparc.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                : 0,
            );

            setQtdParcelas(response.data.data[0].QTD_PARCELAS);
            setPrzMedio(response.data.data[0].PRAZO_MEDIO);

            //REGRA PARCELA MINIMA , SE HOUVER + DE 1 PARCELA
            if (
              Number(listRegrasAtribuidas.valor_minimo_parcelas) >
              Number(vlparc) &&
              Number(response.data.data[0].QTD_PARCELAS) > 1 &&
              prz_pag_readonly == '' && !perfilSuperUser
            ) {
              toast.error(
                'O valor mínimo das parcelas é de: R$ ' +
                listRegrasAtribuidas.valor_minimo_parcelas +
                ' reais',
                {
                  position: toast.POSITION.BOTTOM_CENTER,
                },
              );
              setValidacao(
                validacao.map(item =>
                  item.index === indexTable ? { ...item, validacao: 1 } : item,
                ),
              );
              //setPrzPag('');
              setQtdParcelas(response.data.data[0].QTD_PARCELAS);
              setPrzMedio(response.data.data[0].PRAZO_MEDIO);
            }
            //REGRA PARCELA MAXIMA EM DIAS
            if (
              Number(listRegrasAtribuidas.parcela_maxima_cliente) <
              Number(response.data.data[0].PARCELAS_MAXIMA) &&
              prz_pag_readonly == '' && !perfilSuperUser
            ) {
              toast.error(
                'A parcela máxima não pode exceder: ' +
                listRegrasAtribuidas.parcela_maxima_cliente +
                ' dias',
                {
                  position: toast.POSITION.BOTTOM_CENTER,
                },
              );
              setValidacao(
                validacao.map(item =>
                  item.index === indexTable ? { ...item, validacao: 1 } : item,
                ),
              );
              setPrzPag('');
              setQtdParcelas(response.data.data[0].QTD_PARCELAS);
              setPrzMedio(response.data.data[0].PRAZO_MEDIO);
            }
            //REGRA QTD MAXIMA DE PARCELAS
            if (
              Number(listRegrasAtribuidas.qtd_parcelas_cliente) <
              Number(response.data.data[0].QTD_PARCELAS) &&
              prz_pag_readonly == '' && !perfilSuperUser
            ) {
              toast.error(
                'Limite máximo de parcelas: ' +
                listRegrasAtribuidas.qtd_parcelas_cliente,
              );
              setValidacao(
                validacao.map(item =>
                  item.index === indexTable ? { ...item, validacao: 1 } : item,
                ),
              );
              //console.log(validacao);
              setPrzPag('');
              setQtdParcelas(response.data.data[0].QTD_PARCELAS);
              setPrzMedio(response.data.data[0].PRAZO_MEDIO);
            }

            if (
              Number(listRegrasAtribuidas.prazo_medio_cliente) <
              Number(response.data.data[0].PRAZO_MEDIO) &&
              prz_pag_readonly === '' && !perfilSuperUser
            ) {
              setValidacao(
                validacao.map(item =>
                  item.index === indexTable
                    ? { ...item, validacao: '1' }
                    : item,
                ),
              );
              toast.error(
                'Prazo médio excede limite de : ' +
                listRegrasAtribuidas.prazo_medio_cliente +
                ' dias' +
                Number(response.data.data[0].PRAZO_MEDIO),
                {
                  position: toast.POSITION.BOTTOM_CENTER,
                },
              );
              setPrzPag('');
              setQtdParcelas(response.data.data[0].QTD_PARCELAS);
              setPrzMedio(response.data.data[0].PRAZO_MEDIO);
            }

            // itemRequest.valor_parcelas = totalPedido / countParcel;

            listCart[indexTable].idReq = (
              <Input
                type="text"
                name="referenc"
                defaultValue={itemRequest.referencia}
                disabled={rowIndex !== 0 && !perfilSuperUser ? true : false}
                onChange={e => {
                  itemRequest.referencia = e.target.value;
                }}
                onBlur={e => {
                  setIndexTable(indexTable);
                  setReferenc(e.target.value);
                }}
                style={{
                  minWidth: '6rem',
                  maxWidth: '15rem',
                  height: '50px',
                  fontSize: '12px',
                }}
              />
            );
            listCart[indexTable].prioridade = (
              <Select
                options={prioridades}
                isClearable={false}
                defaultValue={{
                  value: itemRequest.prioridade,
                  label: itemRequest.prioridade,
                }}
                onSelect={setIndexTable(indexTable)}
                isDisabled={!perfilSuperUser?true:false}
                onChange={value => {
                  setIndexTable(indexTable);
                  setPrioridadeEscolhida(value.value);
                  itemRequest.prioridade = value.value;
                }}
              />
            );

            listCart[indexTable].QtdParc = (
              <ParcelNumber paymentTerm={response.data.data[0].QTD_PARCELAS} />
            );
            listCart[indexTable].VlParc = vlparc.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });
            const index = indexTable;
            listCart[indexTable].PrazoMed = response.data.data[0].PRAZO_MEDIO;
            listCart[indexTable].PrazoPag = (
              <CreatableSelect
                //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                options={przPagamento}
                isClearable={false}
                value={{
                  value: itemRequest.prazo_pagamento,
                  label: itemRequest.prazo_pagamento,
                }}
                onSelect={e => {
                  setPrz(e.target.value);
                  setIndexTable(index);
                  itemRequest.prazo_pagamento = e.target.value;
                }}
                onChange={value => {
                  setIndexTable(index);
                  setchangeField(value.value + index);
                  setPrz(value.value);
                  itemRequest.prazo_pagamento = value.value;
                }}
                onCreateOption={inputValue => {

                  if (inputValue.indexOf("(") != -1 && inputValue.indexOf(")") != -1) {


                    const new_string = inputValue.split("(")[0];
                    const prazo_digitado = inputValue.split("(")[1].split(")")[0];
                    //Verifica se existe a string passada antes de ()
                    const isValueFound = przPagamento.some(item => item.label.includes(new_string.toUpperCase()));

                    if (isValueFound) {
                      //Verifica se já existe o prazo médio oriundo de outra opção. 
                      const filteredData = przPagamento.filter(item =>
                        item.label.toUpperCase().includes(new_string.toUpperCase())
                      );
                      //Após obter as opções checar se há o prazo médio como referencia
                      const prz_medio_digitado = calculateAverage(prazo_digitado);
                      const isPrzFound = filteredData.some(item => calculateAverage(item.label.split("(")[1].split(")")[0]) == prz_medio_digitado);


                      if (isPrzFound || perfilSuperUser) {
                        const newOption = { value: inputValue.toUpperCase(), label: inputValue.toUpperCase() };
                        setprzPagamento((prev) => [...prev, newOption]);
                        setPrz(inputValue);
                      }
                      else {
                        toast.error(
                          'Prazo médio não permitido',
                        );

                      }

                    }
                  }
                  else {

                    toast.error(
                      'Padrão informado não localizado, tente novamente',
                    );
                  }
                }}

              />
            );

            setListCart([]);
            ////console.log(validacao);itemRequest.prazo_pagamento
            setListCart(listCart);
          }
        });
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
        } else {
          //   toast.error('Erro ao obter prazo médio.');
        }
      }
    };

    //    console.log('essntrou'+ clienteEscolhido + fieldsReadonly);
    if (prz) {
      getPrazoPagamento();
    }
  }, [
    setPrz,
    prz,
    setVl_Tot_Pedido,
    changeField,
    setReferenc,
    indexTable,
    referenc,
    setIndexTable,
    prioridadeEscolhida,
  ]);

  /*
  useEffect(() => {
    confirmRequest();
  }, [setTranspNome]);
  */
  const loadClients = async (inputValue, callback) => {
    try {
      // var where = `&concat_cliente='*${inputValue}*'&bloqueada=false&ativa=true`;
      inputValue = encodeURIComponent(inputValue);

      var where = `&concat_cliente='*${inputValue}*'&cliente=true&bloqueada=false&ativa=true`;
      setLloading(true);
      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = '';
      var v = ';';
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }

      setLloading(false);
      const data = response.data.data.map(item => {
        return {
          value: item.id,
          label: (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  '<strong>Id:</strong> ' +
                  item.id +
                  '<br> <strong>Nome/Apelido:</strong>' +
                  item.nome_concat +
                  '<br><strong>CNPJ/CPF:</strong>' +
                  item.cnpj +
                  item.cpf +
                  '<br><strong>Cidade:</strong>' +
                  item.enderecoCidade +
                  '/' +
                  item.enderecoEstado +
                  '<hr>',
              }}
            />
          ),
          labelshow: item.id + ' ' + item.nome_concat,
          dados: item,
        };
      });

      // console.log(data);
      return data;
    } catch (err) {
      //              toast.error("Não encontrado");
      setLloading(false);
    }
  };
  //DEBOUNCE CLIENTES
  const loadOptionsClientes = (inputValue, callback) =>
    loadClients(inputValue, callback);

  const debouncedLoadOptionsClientes = debounce(loadOptionsClientes, 3000, {
    leading: false,
  });

  function form_reset(stat) {
    setIdTranspRedespacho('');
    setNomeTranspRedespacho('');
    setIdTransp('');
    setNomeTransp('');
    setIdCliente('');
    setNomeCliente('');
    getTranspData('');
    setVendedorP('');
    setVendedorNomeForm('');
    setfieldsRedespReadonly(stat);
    setfieldsTranspReadonly(stat);
    setfieldsReadonly(stat);
  }

  async function getTranspData(val) {
    if (val > 0) {
      var valor_minimo_da_parcela = 0;
      setListCart([]);
      setFirstload(false);
      setPrzPag('');
      setPrzPagCliente('');
      setObs('');
      setErp_alerta('');

      try {
        const response = await axios.get(
          `${API.clientes}/transportadorasByClienteId/${val}`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
           console.log(response.data.data[0]);
        if (response.data.data[0].PRIORIDADE === null && !perfilSuperUser) {
          toast.error('Cliente sem prioridade informada, ajustar o cadastro.');
          form_reset(true);
        } else {
          if (response.data.data[0].U4_PRAZO_PAGAMENTO === null && !perfilSuperUser) {
            toast.error(
              'Cliente sem prazo de pagamento informado, ajustar o cadastro.',
            );
            form_reset(true);
          } else {
            setIdTranspRedespacho('');
            setNomeTranspRedespacho('');
            setIdTransp('');
            setNomeTransp('');
            setfieldsRedespReadonly(false);
            setfieldsTranspReadonly(false);
            setfieldsReadonly(false);

            setObs(
              response.data.data[0].OBSERVACAO
                ? '\n\n' + response.data.data[0].OBSERVACAO
                : '',
            );

            //console.log(response.data.data[0]);
            if (response.data.data[0].CARTEIRA !== null) {
              setCarteiraId(response.data.data[0].CARTEIRA);
            } //AJUSTE PRA SETAR A CARTEIRA DO CLIENTE

            let vlparc = 0;
            let prazo_medio = 0;
            let qtd_parcelas = 1;

            if (response.data.data[0].U4_PRAZO_PAGAMENTO !== null) {
              setPrzPagCliente(response.data.data[0].U4_PRAZO_PAGAMENTO);

              const response_prazo = await axios.get(
                `${API.prazomedio}?consulta=${response.data.data[0].U4_PRAZO_PAGAMENTO}`,
                {
                  headers: {
                    'x-access-token': token,
                  },
                },
              );

              setlistRegrasAtribuidas(listRegrasAtribuidas => {
                return {
                  ...listRegrasAtribuidas,
                  prazo_pagamento_cliente:
                    response.data.data[0].U4_PRAZO_PAGAMENTO,
                  prazo_medio_cliente: response_prazo.data.data[0].PRAZO_MEDIO
                    ? response_prazo.data.data[0].PRAZO_MEDIO
                    : listRegrasAtribuidas.prazo_medio,
                  parcela_maxima_cliente: response_prazo.data.data[0]
                    .PARCELAS_MAXIMA
                    ? response_prazo.data.data[0].PARCELAS_MAXIMA
                    : listRegrasAtribuidas.vencimento_maximo,
                  qtd_parcelas_cliente: response_prazo.data.data[0].QTD_PARCELAS
                    ? listRegrasAtribuidas.qtd_parcelas
                    : listRegrasAtribuidas.qtd_parcelas,
                };
              });

              //itemSendRequest.map((itemRequest, idx) => {

              itemSendRequest.forEach(async (itemRequest, idx) => {
                validacao[idx].validacao = 0;

                const total = Number(
                  listCart.length > 0
                    ? listCart[idx].total

                      .replace('R$', '')
                      .trim()
                      .replaceAll('.', '')
                      .replace(',', '.')
                    : 0,
                );

                vlparc =
                  response_prazo.data.data[0].QTD_PARCELAS > 0
                    ? total / response_prazo.data.data[0].QTD_PARCELAS
                    : 0;
                qtd_parcelas = response_prazo.data.data[0].QTD_PARCELAS;
                prazo_medio = response_prazo.data.data[0].PRAZO_MEDIO;

                //REGRA PARCELA MINIMA , SE HOUVER + DE 1 PARCELA
                /*
            if (
              Number(listRegrasAtribuidas.valor_minimo_parcelas) >
                Number(vlparc) &&
              Number(response_prazo.data.data[0].QTD_PARCELAS) > 1 &&
              prz_pag_readonly == ""
            ) {
              valor_minimo_da_parcela = 1;
             
              validacao[idx].validacao = 1;
            }*/
              });
              /*if (valor_minimo_da_parcela == 1) {
            toast.error(
              "O valor mínimo das parcelas é de: R$ " +
                listRegrasAtribuidas.valor_minimo_parcelas +
                " reais",
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            }*/
            } //AJUSTE PRA SETAR A CARTEIRA DO CLIENTE
            else {
              setlistRegrasAtribuidas(listRegrasAtribuidas => {
                return {
                  ...listRegrasAtribuidas,
                  prazo_pagamento_cliente: listRegrasAtribuidas.prazo_pagamento,
                  prazo_medio_cliente: listRegrasAtribuidas.prazo_medio,
                  parcela_maxima_cliente:
                    listRegrasAtribuidas.vencimento_maximo,
                  qtd_parcelas_cliente: listRegrasAtribuidas.qtd_parcelas,
                };
              });
            }

            if (response.data.data[0].U4_TIPO_FRETE !== null) {
              setFrete(response.data.data[0].U4_TIPO_FRETE);
            } //AJUSTE PRA SETAR A CARTEIRA DO CLIENTE

            if (response.data.data[0].REDESPACHO_ID !== null) {
              perfil == 'vendedor'
                ? setfieldsRedespReadonly(true)
                : setfieldsRedespReadonly(false);

              setIdTranspRedespacho(
                response.data.data[0].REDESPACHO_ID
                  ? response.data.data[0].REDESPACHO_ID
                  : '',
              );

              setNomeTranspRedespacho(
                response.data.data[0].REDESPACHO_APELIDO
                  ? (
                    response.data.data[0].REDESPACHO_APELIDO +
                    '(' +
                    response.data.data[0].REDESPACHO_CNPJ +
                    ')'
                  ).toUpperCase()
                  : '',
              );
            }
            if (response.data.data[0].TRANSPORTADORA_ID !== null) {
              perfil == 'vendedor'
                ? setfieldsTranspReadonly(true)
                : setfieldsTranspReadonly(false);

              //    //console.log(response.data.data[0].TRANSPORTADORA_ID);
              setIdTransp(response.data.data[0].TRANSPORTADORA_ID);

              setNomeTransp(
                response.data.data[0].TRANSPORTADORA_APELIDO
                  ? (
                    response.data.data[0].TRANSPORTADORA_APELIDO +
                    '(' +
                    response.data.data[0].TRANSPORTADORA_CNPJ +
                    ')'
                  ).toUpperCase()
                  : '',
              );
            }

            //console.log('praaaaaaaaaazo'+prz_pagCliente+'prazz'+response.data.data[0].U4_PRAZO_PAGAMENTO);
            confirmRequest(
              response.data.data[0].U4_PRAZO_PAGAMENTO
                ? response.data.data[0].U4_PRAZO_PAGAMENTO
                : '',
              response.data.data[0].PRIORIDADE
                ? response.data.data[0].PRIORIDADE
                : '',
              prazo_medio,
              qtd_parcelas,
            );
          }
        }
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
          //   sessionStorage.clear();
        } else {
          //      sessionStorage.clear();
          //   toast.error("Erro ao carregar lista de vendedores");
        }
      }
    } else {
      setFirstload(false);
      setPrzPag('');
      setPrzPagCliente('');
      setObs('');
      setErp_alerta('');
      setIdTranspRedespacho('');
      setNomeTranspRedespacho('');
      setIdTransp('');
      setNomeTransp('');
      setfieldsRedespReadonly(false);
      setfieldsTranspReadonly(false);
    }
  }

  async function confirmRequest(
    prazo_pg_default,
    prioridade_default,
    prazo_medio_default,
    qtd_parcelas_default,
    prioridade_readonly,
  ) {
    setisConfirmReq(true);

    const response_prz = await axios.get(`${API.getprzpag}`, {
      headers: {
        'x-access-token': token,
      },
    });
    //const opcoes_prz2 = response_prz.data.data.map(item => console.log(item));
    const opcoes_prz = response_prz.data.data.map(item => {
      return { value: item.DESCRICAO, label: item.DESCRICAO };
    });

    const response = await axios.get(`${API.carrinho}?email=${email}`, {
      headers: {
        'x-access-token': token,
      },
    });
    //alert(firstload);
    //if(firstload === true){
    if (validacao.length == 0) {
      setValidacao(
        response.data.data.map((item, index) => {
          return { index, validacao: 0 };
        }),
      );
    }
    //setValidacao(response.data.data.map((item, index) => { return { index, validacao: 1 } }))

    // console.log(response.data.data.map((item,index)=>{ return {index,validacao:0} }));

    const itemRequestSend = [];
    const idNumberRequest = [];
    var total_pedido_geral = 0;
    var totalPedidoPRG = 0;
    var totalPedidoPE = 0;
    var totalPedidoESP = 0;



    for (let index = 0; index < response.data.data.length; index++) {
      const itemRequest = {
        email,
        numero_pedido: 0,
        cliente_id: idCliente,
        clienteNome: nomeCliente,
        vendedor_id: idVendedorP,
        vendedor_nome: vendedorP,
        operador_id: userIdErp,
        carteira_id: CarteiraId,
        prazo_pagamento: '',
        prazo_pagamento_media: '',
        valor_parcelas: '',
        referencia: '',
        forma_pagamento: '',
        emissao: moment().format('YYYY/MM/DD'),
        empresa_id: 0,
        lista_preco: '1',
        tipo_frete: frete,
        observacoes: obs,
        emails_adicionais: email,
        dt_previsao_entrega: '',
        transportadora: 0,
        transportadora_nome: '',
        redespacho: 0,
        redespacho_nome: '',
        status: '$digitado',
        items: [...response.data.data[index]],
      };

      const respedidos = await axios.get(`${API.numeropedido_erp}`, {
        headers: {
          'x-access-token': token,
        },
      });
      const handleCreate = (inputValue) => {

        //Verifica se contem ()


      };

      const listProducts = [];
      let companyName = '';
      let companyId = 0;
      let dateProgram = '';
      let totalPedido = 0;
      let data_prog = '';

      // Coleta os itens do pedido (Produtos)
      for (let idx = 0; idx < response.data.data[index].length; idx++) {
        const i = response.data.data[index][idx];
        const item = {
          ID: i.ID,
          ITEM_ID: i.ITEM_ID,
          ITEM_NOME: i.ITEM_NOME,
          EMPRESA_ID: i.EMPRESA_ID,
          EMPRESA_APELIDO: i.EMPRESA_APELIDO,
          DATA_PROGRAMACAO: i.DATA_PROGRAMACAO,
          QUANTIDADE: i.QUANTIDADE,
          ITEM_UNIDADE: i.ITEM_UNIDADE,
          ITEM_GRADE: i.ITEM_GRADE,
          LISTA_PRECO: 'A90',
          VALOR_UNITARIO: i.VALOR_UNITARIO,
          VALOR_UNITARIO_PADRAO: i.VALOR_UNITARIO_PADRAO,
          VALOR_TOTAL: i.VALOR_TOTAL,
          TIPO_VENDA: i.TIPO_VENDA,
          PROGRAMACAO_NUMERO: i.PROGRAMACAO_NUMERO,
          PROGRAMACAO_ITEM_ID: i.PROGRAMACAO_ITEM_ID,
          CARRINHO_USUARIO_ID: i.CARRINHO_USUARIO_ID,
          CARRINHO_CONTA_ID: i.CARRINHO_CONTA_ID,
        };
        listProducts.push(item);
        totalPedido = totalPedido + i.QUANTIDADE * i.VALOR_UNITARIO;

        //TOTAL PEDIDO PROGRAMACAO
        if (i.TIPO_VENDA.includes('PRG')) {
          totalPedidoPRG = totalPedidoPRG + i.QUANTIDADE * i.VALOR_UNITARIO;
          itemRequest.tipo_venda = i.TIPO_VENDA;
        }
        if (i.TIPO_VENDA.includes('ESP')) {
          totalPedidoESP = totalPedidoESP + i.QUANTIDADE * i.VALOR_UNITARIO;
          itemRequest.tipo_venda = i.TIPO_VENDA;
        }
        if (i.TIPO_VENDA.includes('P.E.')) {
          totalPedidoPE = totalPedidoPE + i.QUANTIDADE * i.VALOR_UNITARIO;
          itemRequest.tipo_venda = '';
        }
        if (i.TIPO_VENDA.includes('P.V')) {
          itemRequest.tipo_venda = 'pre_venda';
        } else if (Number(i.EMPRESA_ID) == 5) {
          itemRequest.tipo_venda = 'web_programacao_po';
        } else if (i.TIPO_VENDA.includes('P.E.')) {
          itemRequest.tipo_venda = 'web_pronta_entrega';
        } else if (i.TIPO_VENDA.includes('PRG')) {
          itemRequest.tipo_venda = 'web_programacao';
        } else {
          itemRequest.tipo_venda = 'pre_venda';
        }

        total_pedido_geral =
          total_pedido_geral + i.QUANTIDADE * i.VALOR_UNITARIO;
        if (companyName === '') {
          companyName = i.EMPRESA_APELIDO;
          dateProgram = i.TIPO_VENDA.includes('P.E.')
            ? i.TIPO_VENDA
            : moment(i.DATA_PROGRAMACAO).format('DD/MM/YYYY');
          companyId = i.EMPRESA_ID;
          data_prog = i.DATA_PROGRAMACAO;
        }
      }

      itemRequest.numero_pedido = respedidos.data.data.numero;
      itemRequest.empresa_id = companyId;
      itemRequest.items = listProducts;
      itemRequest.dt_previsao_entrega = data_prog;
      itemRequest.prioridade = prioridade_default;
      itemRequest.prazo_pagamento =
        qtd_parcelas_default > 0 &&
          Number(listRegrasAtribuidas.valor_minimo_parcelas) >
          Number(totalPedido / qtd_parcelas_default)
          ? prazo_medio_default
          : prazo_pg_default;
      itemRequest.vl_parcelas =
        qtd_parcelas_default > 0
          ? Number(listRegrasAtribuidas.valor_minimo_parcelas) >
            Number(totalPedido / qtd_parcelas_default)
            ? totalPedido
            : totalPedido / qtd_parcelas_default
          : 0;
      itemRequest.prazo_medio = prazo_medio_default;
      itemRequest.qtd_parcelas = qtd_parcelas_default
        ? qtd_parcelas_default
        : 1;
      const row = createRequest(
        <ItemProdutos itemCart={listProducts} />,
        companyName,
        <Select
          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
          options={prioridades}
          isClearable={false}
          isDisabled={!perfilSuperUser?true:false}
          //isOptionDisabled={fieldsReadonly? {value: prioridade_default, label: prioridade_default}:false}
          defaultValue={{
            value: prioridade_default,
            label: prioridade_default,
          }}
          onSelect={setIndexTable(index)}
          onChange={value => {
            setIndexTable(index);
            setPrioridadeEscolhida(value.value);
            itemRequest.prioridade = value.value;
          }}
        />,
        <Input
          type="text"
          name="referenc"
          defaultValue={itemRequest.referencia}
          disabled={index !== 0  && !perfilSuperUser? true : false}
          onChange={e => {
            itemRequest.referencia = e.target.value;
          }}
          onBlur={e => {
            setIndexTable(index);
            setReferenc(e.target.value);
          }}
          style={{
            minWidth: '4rem',
            maxWidth: '15rem',
            height: '38px',
            fontSize: '12px',
          }}
        />,
        <CreatableSelect
          //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
          options={opcoes_prz}
          isClearable={false}
          isDisabled={prazo_medio_default==0? true : false}
          defaultValue={{
            value: itemRequest.prazo_pagamento,
            label: itemRequest.prazo_pagamento,
          }}
          onSelect={e => {
            setPrz(e.target.value);
            setIndexTable(index);
            itemRequest.prazo_pagamento = e.target.value;
          }}
          onChange={value => {
            setchangeField(value.value + index);
            setIndexTable(index);
            setPrz(value.value);
            itemRequest.prazo_pagamento = value.value;
          }}
          onCreateOption={inputValue => {

            if (inputValue.indexOf("(") != -1 && inputValue.indexOf(")") != -1) {


              const new_string = inputValue.split("(")[0];
              const prazo_digitado = inputValue.split("(")[1].split(")")[0];
              //Verifica se existe a string passada antes de ()
              const isValueFound = opcoes_prz.some(item => item.label.includes(new_string.toUpperCase()));

              if (isValueFound) {
                //Verifica se já existe o prazo médio oriundo de outra opção. 
                const filteredData = opcoes_prz.filter(item =>
                  item.label.toUpperCase().includes(new_string.toUpperCase())
                );
                //Após obter as opções checar se há o prazo médio como referencia
                const prz_medio_digitado = calculateAverage(prazo_digitado);
                const isPrzFound = filteredData.some(item => calculateAverage(item.label.split("(")[1].split(")")[0]) == prz_medio_digitado);

                if (isPrzFound) {
                  const newOption = { value: inputValue.toUpperCase(), label: inputValue.toUpperCase() };
                  
                  setprzPagamento((prev) => [...prev, newOption]);
                  setPrz(inputValue);
                }
                else {
                  toast.error(
                    'Prazo médio não permitido',
                  );

                }

              }
            }
            else {

              toast.error(
                'Padrão informado não localizado, tente novamente',
              );
            }
          }}

        />,
        itemRequest.prazo_medio,
        itemRequest.vl_parcelas.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),

        <ParcelNumber paymentTerm={itemRequest.qtd_parcelas} />,

        dateProgram,
        totalPedido.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      );
      idNumberRequest.push(row);
      itemRequestSend.push(itemRequest);
    }
    const row2 = createRequest(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Total Pedido ',
      total_pedido_geral.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    );

    const row = createRequest(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Total Pedido PRG',
      totalPedidoPRG.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    );

    const rowesp = createRequest(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Total Pedido ESP',
      totalPedidoESP.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    );

    const rowPE = createRequest(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Total Pedido PE',
      totalPedidoPE.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    );

    idNumberRequest.push(rowesp);
    idNumberRequest.push(rowPE);
    idNumberRequest.push(row);
    idNumberRequest.push(row2);

    setVl_Tot_Pedido(total_pedido_geral);
    setVl_Tot_PedidoProgramacao(totalPedidoPRG);
    setVl_Tot_PedidoPE(totalPedidoPE);
    setVl_Tot_PedidoESP(totalPedidoESP);

    // console.log('total do pedido' + total_pedido_geral);
    setItemSendRequest(itemRequestSend);
    setListCart(idNumberRequest);

    setLloading(false);
  }

  async function orderViewCancel() {
    setIsViewRequest(false);
    setisConfirmReq(true);
  }
  async function orderView() {
    //setIsViewRequest();
    //if (isConfirmReq && !isFinishRequest && !isViewRequest && !progress) {
    //setIsViewRequest(true);
    for (let index = 0; index < itemSendRequest.length; index++) {
      const i = itemSendRequest[index];
      i.cliente_id = idCliente;
      i.cliente_nome = nomeCliente;
      i.vendedor_id = idVendedorP;
      i.observacoes = obs;
      i.carteira_id = CarteiraId;
      i.transportadora = idTransp;
      i.transportadora_nome = nomeTransp;
      i.redespacho_nome = nomeTranspRedespacho;
      i.redespacho = idTranspRedespacho;
      //   i.prazo_pagamento = prz_pag;
      i.tipo_frete = frete;
    }

    //  if (prz_pag !== '') {
    setIsViewRequest(true);
    /*  }
    else {
      toast.error(
        'Prazo de pagamento não pode ser nulo',
      );
      //  setIsViewRequest(false);
      // setisConfirmReq(true);
    }
*/
  }

  async function orderConfirmation() {
    if (idCliente.length === 0 || idVendedorP === 0) {
      toast.success('Favor preencher os campos obrigatórios');
    } else {
      setIsFinishRequest(true);
      setLloading(true);
      var responseContatos = null;
      let emails = email ? email + ',' : '';
      let emails_disparo_automatico = email
        ? email + ',pedido_portal_web@general-textile.com,'
        : ''; //REGRA 4
      try {
        var responseContatos = null;

        //obtem endereço de email do vendedor associado
        const EmailVendedorAssociado = await axios.get(`${API.usuarios}`, {
          headers: {
            'x-access-token': token,
          },
        });

        const emailsvendedores = EmailVendedorAssociado.data.data;
        const returnVendedores = emailsvendedores
          ? emailsvendedores.filter(function (obj) {
            return obj.USUARIO_CONTA_ID_ERP === String(idVendedorP);
          })
          : '';

        if (returnVendedores[0]) {
          emails = emails + returnVendedores[0].USUARIO_EMAIL + ',';
          /*          emails_disparo_automatico =
            emails_disparo_automatico + returnVendedores[0].USUARIO_EMAIL + ",";
  */
        }

        responseContatos = await axios.get(
          `${API.clientes}/${idCliente}/contatos`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );

        if (responseContatos) {
          var responseContatosNew = responseContatos.data.data.sort(
            (a, b) => a.prioridade - b.prioridade,
          );

          responseContatosNew.map(i => {
            if (i.tipo === 'EMAIL' || i.tipo === 'EMAIL_NFE') {
              if (i.tipo === 'EMAIL') {
                emails_disparo_automatico =
                  emails_disparo_automatico + `${i.descricao},`;
              }

              emails = emails + `${i.descricao},`;
            }
          });
          emails = emails.slice(0, -1);
          emails_disparo_automatico = emails_disparo_automatico.slice(0, -1);

          setEmail(emails.split(','));
          setEmailAutomatico(emails_disparo_automatico.split(','));
        }
      } catch (error) {
        //
      }

      const listRequestResponse = [];

      //Inserindo os pedidos
      for (let index = 0; index < itemSendRequest.length; index++) {
        const i = itemSendRequest[index];
        i.referencia = itemSendRequest[0].referencia; //REGRA 3
        i.cliente_id = idCliente;
        i.nome_cliente = nomeCliente;
        i.vendedor_id = idVendedorP;
        i.carteira_id = CarteiraId;
        i.statusClient = statusClient.length > 0 ? 'restricao' : '';

        i.observacoes = 'Prioridade: ' + i.prioridade + '\n' + obs;
        i.transportadora = idTransp;
        i.redespacho = idTranspRedespacho;
        i.tipo_frete = frete;
        //console.log(i.tipo_venda);
        try {
          const response2 = await axios.post(`${API.pedidos}`, i, {
            headers: {
              'x-access-token': token,
            },
          });

          for (let index = 0; index < response2.data.data.length; index++) {
            const pedido = response2.data.data[index];

            if (
              pedido &&
                response2.data.success &&
                parseInt(response2.data.status) === 200 &&
                pedido.numeroSistema &&
                pedido.conta
                ? pedido.conta.nome
                : ''
            ) {
              // console.log('pedido ok');
              //   console.log(pedido.itens);
              //limpa carrinho(somente itens inseridos)
              for (let x = 0; x < pedido.itens.length; x++) {
                try {
                  //   console.log(`${pedido.itens[x].item.id}`);
                  await axios.delete(
                    `${API.carrinhoremoveitem}/${pedido.itens[x].item.id}`,
                    {
                      headers: {
                        'x-access-token': token,
                      },
                    },
                  );
                  //   toast.success("Item removido do carrinho com sucesso");

                  //window.location.reload();
                } catch (err) {
                  console.log(err);
                }
              }

              //atualizar carrinho que pedido foi gerado
              listRequestResponse.push(
                createDataFinishRequest(
                  '',
                  <IconRequestPass style={{ color: 'green' }} />,
                  <ItemProdutos itemCart={i.items} />,
                  <Pdf num={pedido.numeroSistema} status={''} />,

                  pedido.numeroSistema,
                  i.referencia,
                  i.prazo_pagamento,
                  moment(i.dt_previsao_entrega).format('DD/MM/YYYY'),
                  pedido.conta.nome,
                  i.tipo_venda,
                ),
              );
            } else {
              //     console.log("pedido não ok");
              toast.error(
                'Erro ao inserir. Os itens serão mantidos em seu carrinho',
              );

              listRequestResponse.push(
                createDataFinishRequest(
                  '',
                  <IconRequestfailure style={{ color: 'red' }} />,
                  <ItemProdutos itemCart={i.items} />,
                  '',
                  i.referencia,
                  i.prazo_pagamento,
                  moment(i.dt_previsao_entrega).format('DD/MM/YYYY'),
                  i.clienteNome,
                  pedido && pedido.mensagem ? pedido.mensagem : '',
                ),
              );
            }
          }
        } catch (error) { }
      }

      //console.log(listRequestResponse);

      //console.log(listRequestResponse);
      //Enviando pedido automaticamente
      if (emails_disparo_automatico != '') {
        var array_pedidos_auto = [];

        for (let index = 0; index < listRequestResponse.length; index++) {
          const i = listRequestResponse[index];
          if (i.numeroSistema > 0) {
            array_pedidos_auto.push({
              numero: i.numeroSistema,
              status: i.status,
            });
          }
        }

        const data_sendEmail = {
          usuario: email,
          emails: emails_disparo_automatico,
          titulo: '',
          descricao: 'Pedido emitido pelo sistema SalesBreath',
          pedidos: array_pedidos_auto,
        };

        try {
          toast.success('Enviando email dos pedidos,aguarde.', {
            autoClose: 10000,
          });

          setLloading(true);

          const response_mail = await axios.post(
            `${API.sendmailPedidos}`,
            data_sendEmail,
            {
              headers: {
                'x-access-token': token,
              },
            },
          );
          setLloading(false);

          for (
            let idxmail = 0;
            idxmail < response_mail.data.data.data.length;
            idxmail++
          ) {
            listRequest[idxmail].mailsent = 'Email Enviado';
          }
          //  setListRequest(listRequest);
        } catch (error) {
          setLloading(false);
          toast.error(
            error.response && error.response.data.message
              ? error.response.data.message
              : 'Erro ao enviar email',
          );
        }
      }
      setListRequest(listRequestResponse);
      setLloading(false);
    }
  }

  async function cancelRequest() {
    setListCart([]);
    setLista([]);
    setisConfirmReq(false);
    history.push('/produtos');
  }

  async function sendUserNotification(e) {
    e.preventDefault();
    const emailSentTo = emails.join();
    var answer = window.confirm('Tem certeza que deseja enviar o email ?');
    if (answer) {
      const array_pedidos = [];

      for (let index = 0; index < listRequest.length; index++) {
        const i = listRequest[index];
        //       console.log(i);
        if (i.numeroSistema > 0) {
          array_pedidos.push({ numero: i.numeroSistema, status: i.status });
        }
      }

      const data_sendEmail = {
        usuario: email,
        emails: emailSentTo,
        titulo: '',
        descricao: 'Pedido emitido pelo sistema SalesBreath',
        pedidos: array_pedidos,
      };
      //console.log("dados a enviar");
      //console.log(data_sendEmail);
      try {
        toast.success('Enviando email,aguarde.', { autoClose: 10000 });

        setLloading(true);

        const response_mail = await axios.post(
          `${API.sendmailPedidos}`,
          data_sendEmail,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        setLloading(false);

        for (
          let idxmail = 0;
          idxmail < response_mail.data.data.data.length;
          idxmail++
        ) {
          listRequest[idxmail].mailsent = 'Email Enviado';
        }
        setListRequest(listRequest);
      } catch (error) {
        setLloading(false);
        toast.error(
          error.response && error.response.data.message
            ? error.response.data.message
            : 'Erro ao enviar email',
        );
      }
    } else {
      return null;
    }
  }

  // Monta a tela conforme condiçoes das variaveis:
  //
  // isConfirmReq:
  // se for FALSE, renderiza tela de produtos para
  // inserção no carrinho, caso seja TRUE, carrinho
  // com produto para realizar processo de finalização de pedido.
  //
  // isFinishRequest:
  // se for FALSE, processo de finalização do pedido em curso.
  // se for TRUE, iniciou o processo de finalização, enviou os pedidos
  // para o back end, e esta exibindo o resulta em tela para o usuário.
  if (!isConfirmReq) {
    return null;
  } else {
    if (progress) {
      return (
        <>
          <div className={classes.progress}>
            <span alignItems="center">
              Estamos carregando a lista de clientes, favor aguardar.
            </span>
            <LinearProgress />
          </div>
        </>
      );
    }

    if (isConfirmReq && !isFinishRequest && !isViewRequest && !progress) {
      // Inicia o processo de confirmação de pedido

      return (
        <>
          {' '}
          <FormAuto>
            <div className="lineForm">
              <Pesquisa>
                <div>
                  <Typography component={'div'}>
                    <FormAuto>
                      <Grid
                        container
                        spacing={3}
                        style={{
                          width: '100%',
                          minHeight: '100%',
                        }}
                      >
                        <Grid item xs={12} lg={4} md={4}>
                          <Card className={classes.card}>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'DEFINIÇÕES DA VENDA'}
                            />

                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                style={{ width: '100%', background: 'white' }}
                              >
                                <Grid item xs={12} lg={12} md={12}>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{ width: '100%' }}
                                  >
                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={11}
                                      md={11}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <label>Cliente</label>

                                      <Async
                                        //onChange={e => (itemRequest.prazo_pagamentor = e.target.value)}
                                        loadOptions={
                                          debouncedLoadOptionsClientes
                                        }
                                        cacheOptions
                                        isClearable={true}
                                        noOptionsMessage={() =>
                                          'Nenhuma opção encontrada'
                                        }
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: base => ({
                                            ...base,
                                            zIndex: 100,
                                          }),
                                          container: base => ({
                                            ...base,
                                            minWidth: '8rem',
                                          }),
                                        }}
                                        placeholder="Clientes"
                                        onSelect={e => {
                                          getTranspData(e.target.value);
                                        }}
                                        value={{
                                          label: nomeCliente ? nomeCliente : '',
                                          value: idCliente ? idCliente : '',
                                        }}
                                        onChange={value => {
                                          const valor =
                                            value === null ? '' : value.value;

                                          if (valor > 1) {
                                            setIdCliente(valor);
                                            setNomeCliente(value.labelshow);
                                            perfil == 'vendedor' &&
                                              value.dados.vendedorPadrao
                                              ? setfieldsReadonly(true)
                                              : setfieldsReadonly(false);

                                            getTranspData(valor);

                                            setIdVendedorP(
                                              value.dados.vendedorPadrao
                                                ? value.dados.vendedorPadrao.id
                                                : '',
                                            );
                                            setVendedorP(
                                              value.dados.vendedorPadrao
                                                ? value.dados.vendedorPadrao
                                                  .nome
                                                : '',
                                            );
                                          } else {
                                            setIdCliente('');
                                            setNomeCliente('');
                                            getTranspData('');
                                            setVendedorP('');
                                            setVendedorNomeForm('');
                                            setfieldsReadonly(false);
                                          }
                                        }}
                                      />
                                    </Grid>
                                    {idCliente && (
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <label></label>
                                        <InfoClient
                                          parentData={setDataParent}
                                          data={idCliente}
                                          prazo={prz_pagCliente}
                                        />
                                      </Grid>
                                    )}
                                    <Grid
                                      className="index2"
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <div className="index2">
                                        <label>Vendedor</label>
                                        <Select
                                          options={autoVendedor}
                                          isClearable={
                                            perfil !== 'vendedor' ? true : false
                                          }
                                          value={{
                                            label: vendedorP ? vendedorP : '',
                                            value: idVendedorP
                                              ? idVendedorP
                                              : '',
                                          }}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: base => ({
                                              ...base,
                                              zIndex: 99,
                                            }),

                                            container: base => ({
                                              ...base,
                                              minWidth: '8rem',
                                            }),
                                          }}
                                          isDisabled={fieldsReadonly}
                                          onChange={value => {
                                            const valor =
                                              value === null ? '' : value.value;
                                            if (valor > 1) {
                                              setIdVendedorP(
                                                value.value ? value.value : '',
                                              );
                                              setVendedorP(
                                                value.label ? value.label : '',
                                              );
                                            } else {
                                              setIdVendedorP('');
                                              setVendedorP('');
                                            }
                                          }}
                                        />{' '}
                                      </div>
                                    </Grid>

                                    <Grid
                                      className="index"
                                      item
                                      xs={12}
                                      sm={12}
                                      lg={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        color: 'red',
                                        fontFamily:
                                          'Times New Roman, Times, serif',
                                      }}
                                    >
                                      {
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: statusClient,
                                          }}
                                        ></span>
                                      }
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>

                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'DEFINIÇÕES DE TRANSPORTE'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={2}
                                style={{ width: '100%', background: 'white' }}
                              >
                                <Grid item xs={12} lg={12} md={12}>
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{ width: '100%' }}
                                  >
                                    <Grid
                                      className="index"
                                      item
                                      xs={12}
                                      sm={12}
                                      lg={12}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <label>Frete*</label>
                                      <select
                                        id="tipo_frete"
                                        name="tipo_frete"
                                        style={{
                                          minWidth: '10rem',
                                          height: 'calc(2em + 0.75rem + 2px)',
                                          fontSize: '12px',
                                        }}
                                        value={frete}
                                        disabled={fieldsReadonly}
                                        defaultValue={frete}
                                        onChange={e => setFrete(e.target.value)}
                                      >
                                        <option></option>
                                        <option>DESTINATARIO</option>
                                        <option>EMITENTE</option>
                                      </select>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{ width: '100%' }}
                                      >
                                        <Grid
                                          className="index2"
                                          item
                                          sm={12}
                                          lg={12}
                                          md={12}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                          }}
                                        >
                                          <div className="index2">
                                            <label>Transportadora</label>
                                            <Select
                                              options={autoTransp}
                                              isClearable={
                                                perfil !== 'vendedor'
                                                  ? true
                                                  : false
                                              }
                                              value={{
                                                label: nomeTransp
                                                  ? nomeTransp
                                                  : '',
                                                value: idTransp ? idTransp : '',
                                              }}
                                              menuPortalTarget={document.body}
                                              menuPlacement="top"
                                              styles={{
                                                menuPortal: base => ({
                                                  ...base,
                                                  zIndex: 194,
                                                }),

                                                container: base => ({
                                                  ...base,
                                                  minWidth: '8rem',
                                                }),
                                              }}
                                              isDisabled={fieldsTranspReadonly}
                                              onChange={value => {
                                                const valor =
                                                  value === null
                                                    ? ''
                                                    : value.value;
                                                if (valor > 1) {
                                                  setIdTransp(
                                                    value.value
                                                      ? value.value
                                                      : '',
                                                  );
                                                  setNomeTransp(
                                                    value.label
                                                      ? value.label
                                                      : '',
                                                  );
                                                }
                                              }}
                                            />{' '}
                                          </div>
                                        </Grid>
                                        <Grid
                                          className="index2"
                                          item
                                          sm={12}
                                          lg={12}
                                          md={12}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                          }}
                                        >
                                          <div className="index2">
                                            <label>Redespacho</label>
                                            <Select
                                              options={autoTransp}
                                              isClearable={
                                                perfil !== 'vendedor'
                                                  ? true
                                                  : false
                                              }
                                              value={{
                                                label: nomeTranspRedespacho
                                                  ? nomeTranspRedespacho
                                                  : '',
                                                value: idTranspRedespacho
                                                  ? idTranspRedespacho
                                                  : '',
                                              }}
                                              menuPortalTarget={document.body}
                                              menuPlacement="top"
                                              styles={{
                                                menuPortal: base => ({
                                                  ...base,
                                                  zIndex: 195,
                                                }),

                                                container: base => ({
                                                  ...base,
                                                  minWidth: '8rem',
                                                }),
                                              }}
                                              isDisabled={fieldsRedespReadonly}
                                              onChange={value => {
                                                const valor =
                                                  value === null
                                                    ? ''
                                                    : value.value;
                                                if (valor > 1) {
                                                  setIdTranspRedespacho(
                                                    value.value
                                                      ? value.value
                                                      : '',
                                                  );
                                                  setNomeTranspRedespacho(
                                                    value.label
                                                      ? value.label
                                                      : '',
                                                  );
                                                }
                                              }}
                                            />{' '}
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                          <label>Observações</label>

                                          <div className="input">
                                            <textarea
                                              rows="5"
                                              cols="30"
                                              name="obs"
                                              type="text"
                                              defaultValue={obs}
                                              onChange={e =>
                                                setObs(e.target.value)
                                              }
                                            />
                                          </div>
                                        </Grid>
                                        <div>
                                          <label>* Campos obrigatórios</label>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>

                        <Grid item xs={12} lg={8} md={8}>
                          <Card className={classes.card}>
                            <CardHeader
                              className={classes.header}
                              component={Typography}
                              title={'PEDIDOS'}
                            />
                            <CardContent>
                              <Grid
                                container
                                spacing={0}
                                style={{
                                  width: '100%',
                                  minHeight: '100%',
                                }}
                              >
                                <Grid
                                  className="index2"
                                  item
                                  sm={12}
                                  lg={12}
                                  md={12}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                  }}
                                >
                                  <DataTable
                                    rows={listCart}
                                    rowHead={headRequest}
                                    title={''}
                                    titleNoData={''}
                                    searchInput={false}
                                    load={loading}
                                  />
                                  <ToastContainer />


                                  {(idCliente.length !== 0) &
                                    (idVendedorP.length !== 0) &
                                    (Object.values(validacao).filter(
                                      array => array.validacao > 0,
                                    ).length ===
                                      0) &
                                    (frete != '') ? (
                                    <ButtonRequest
                                      bg="#00acc1"
                                      disabled={isRequest}
                                      onClick={orderView}
                                    >
                                      Visualizar Pedidos
                                    </ButtonRequest>
                                  ) : (
                                    ''
                                  )}
                                  <ButtonRequest
                                    bg="#ff5858"
                                    disabled={isRequest}
                                    onClick={cancelRequest}
                                  >
                                    Cancelar
                                  </ButtonRequest>

                                  <br />
                                  <br />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </FormAuto>
                  </Typography>
                </div>
              </Pesquisa>

              <ToastContainer />
            </div>
          </FormAuto>
        </>
      );
    } else if (isFinishRequest) {
      return (
        <PaperStyle>
          <div className="div">
            <h1 style={{ marginTop: '18px' }}>Notificação do Cliente</h1>
            <FormAuto>
              <Grid container spacing={1}>
                <Grid className="index" item sm={12} lg={6} md={12}>
                  <label>Email de notificação</label>
                  <ReactMultiEmail
                    placeholder="Emails que receberam o pdf"
                    emails={emails}
                    onChange={_emails => {
                      setEmail(_emails);
                    }}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span
                            data-tag-handle
                            onClick={() => removeEmail(index)}
                          >
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />
                </Grid>
                <ButtonRequest
                  bg="#00acc1"
                  disabled={isRequest}
                  onClick={e => sendUserNotification(e)}
                >
                  Enviar Notificação
                </ButtonRequest>
              </Grid>
            </FormAuto>
          </div>

          <DataTable
            rows={listRequest}
            rowHead={rowHeadRequest}
            title={''}
            titleNoData={''}
            searchInput={false}
            load={loading}
          />

          <br />
          <br />
        </PaperStyle>
      );
    } else if (isViewRequest) {
      function subtotal(items) {
        return items
          .map(({ VALOR_TOTAL }) => VALOR_TOTAL)
          .reduce((sum, i) => sum + i, 0);
      }

      function total() {
        var tot = 0;
        itemSendRequest.map(item => {
          tot = tot + subtotal(item.items);
        });
        return tot;
      }

      return (
        <PaperStyle>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCellPrimary colSpan="6" align="center">
                    Pedido(s) {' de: ' + itemSendRequest[0].cliente_nome}
                  </StyledTableCellPrimary>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Cliente</StyledTableCell>
                  <StyledTableCell align="center">Vendedor</StyledTableCell>
                  <StyledTableCell align="center">
                    Transportadora
                  </StyledTableCell>
                  <StyledTableCell align="center">Redespacho</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={itemSendRequest[0].index}>
                  <StyledTableCell>
                    {itemSendRequest[0].cliente_nome}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].vendedor_id + '-' + vendedorP}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].transportadora +
                      '-' +
                      itemSendRequest[0].transportadora_nome}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].redespacho +
                      '-' +
                      itemSendRequest[0].redespacho_nome}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br></br>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Código</StyledTableCell>
                  <StyledTableCell align="left">Item</StyledTableCell>
                  <StyledTableCell align="left">Grade</StyledTableCell>
                  <StyledTableCell align="right">Empresa</StyledTableCell>
                  <StyledTableCell align="right">Programação</StyledTableCell>
                  <StyledTableCell align="right">Quantidade</StyledTableCell>
                  <StyledTableCell align="right">
                    Valor Unitário
                  </StyledTableCell>
                  <StyledTableCell align="right">Valor Total</StyledTableCell>
                </TableRow>
              </TableHead>

              {itemSendRequest.map((item, index) => (
                <TableBody>
                  {item.items.map(row => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell>{row.ITEM_ID}</StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ITEM_NOME}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.ITEM_GRADE}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.EMPRESA_APELIDO}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.TIPO_VENDA}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.QUANTIDADE + ' ' + row.ITEM_UNIDADE}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(row.VALOR_UNITARIO)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {ccyFormat(row.VALOR_TOTAL)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <TableRow>
                    <StyledTableCell rowSpan={6} colSpan={6} align="right" />
                    <StyledTableCell colSpan={1} align="right">
                      Subtotal
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {ccyFormat(subtotal(item.items))}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell colSpan={1} align="right">
                      Prazo de pagamento
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.prazo_pagamento}
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell colSpan={1} align="right">
                      Prioridade
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {item.prioridade}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              ))}

              <TableRow>
                <StyledTableCell colSpan={7} align="right">
                  Total
                </StyledTableCell>
                <StyledTableCell align="right">
                  {ccyFormat(total())}
                </StyledTableCell>
              </TableRow>
            </Table>
          </TableContainer>
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell rowSpan="6" align="center">
                    Observações
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={itemSendRequest[0].index}>
                  <StyledTableCell align="left">
                    {itemSendRequest[0].observacoes}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <ButtonRequest
            bg="#00acc1"
            disabled={isRequest}
            onClick={orderConfirmation}
          >
            Confirmar Pedido
          </ButtonRequest>
          <ButtonRequest bg="#00acc1" onClick={orderViewCancel}>
            Voltar
          </ButtonRequest>
        </PaperStyle>
      );
    }
  }
}

FinalizaPedido.propTypes = {
  itemCart: PropTypes.arrayOf(PropTypes.object).isRequired,
  confirma: PropTypes.bool,
  Transp: PropTypes.arrayOf(PropTypes.object).isRequired,
  Clients: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default connect()(FinalizaPedido);
