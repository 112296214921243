import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Autocomplete from 'react-autocomplete';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataTable from 'components/Table/Table.js';
import ModalCreateContatos from '../ModalCreateContatos';
import EditContato from '../ModalEditContatos';
import DeleteContato from '../ModalDeleteContatos';
import TextField from '@material-ui/core/TextField';
import filesize from "filesize";
import Dropzone from "../../Upload";
import Box from '@material-ui/core/Box';
//import Pedidos from '../../../views/Pedidos';
import MenuItem from '@material-ui/core/MenuItem';

import { Chart } from 'react-google-charts';
import moment from 'moment';
import { API } from '../../../config/api';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardFooter from 'components/Card/CardFooter.js';
import 'react-tabs/style/react-tabs.css';
import Icon from '@material-ui/core/Icon';
import InputMask from "react-input-mask";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';




// @material-ui/icons
import Store from '@material-ui/icons/Store';
import { Form, ButtonStyled } from "./styles";
import Pedidos from 'views/Pedidos/Pedidos';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '80%',
    maxHeight: '80%',
    minHeight: '80%',

    minWidth: '80%',
    overflow: 'scroll',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  button: {
    border: 0,
    borderRadius: '20px',
    backgroundColor: '#00acc1',
    color: '#fff',
    padding: '5px',
    cursor: 'pointer',
  },
}));

export default function ModalClientes({ data }) {
  // Token
  const token = sessionStorage.getItem('token');
  const perfil = sessionStorage.getItem('perfil');
  const email = sessionStorage.getItem('email');

  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isopen, setisOpen] = React.useState(true);
  const [pedidosOpen, setPedidosOpen] = React.useState(false);
  const [required, setRequired] = React.useState(false);


  const [upload, setUpload] = useState("");
  const [files, setFiles] = useState("");
  const [files_saved, setFiles_saved] = useState("");

  const [mascara, setMascara] = React.useState(null);
  const [autoTransp, setautoTransp] = useState([]);
  const [autoTranspRedespacho, setautoTranspRedespacho] = useState('');
  const [nomeTransp, setNomeTransp] = useState();
  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('');

  const [tipoRequired, setTipoRequired] = useState(true);

  // let valueTransp = data.propriedades.TRANSPORTADORA === null ? '' : data.propriedades.TRANSPORTADORA;
  //let valueRedespacho = data.propriedades.REDESPACHO === null ? '' : data.propriedades.REDESPACHO;

  //const [idTransp, setIdTransp] = useState(valueTransp);
  //const [idTranspRedespacho, setIdTranspRedespacho] = useState(valueRedespacho);

  const [fieldsCustom, setfieldsCustom] = useState({
    AGENCIA1: data.propriedades.AGENCIA1,
    AGENCIA2: data.propriedades.AGENCIA2,
    BANCO1: data.propriedades.BANCO1,
    BANCO2: data.propriedades.BANCO2,
    CLIENTE_TENDECIAS: data.propriedades.CLIENTE_TENDECIAS,
    COMPRADOR: data.propriedades.COMPRADOR,
    CONTACORRENTE1: data.propriedades.CONTACORRENTE1,
    CONTACORRENTE2: data.propriedades.CONTACORRENTE2,
    CONTATOCC1: data.propriedades.CONTATOCC1,
    CONTATOCC2: data.propriedades.CONTATOCC2,
    EMAIL: data.propriedades.EMAIL,
    EMAIL_COMPRADOR: data.propriedades.EMAIL_COMPRADOR,
    EMAIL_CONTASAPAGAR: data.propriedades.EMAIL_CONTASAPAGAR,
    EMAIL_ESTILISTA: data.propriedades.EMAIL_ESTILISTA,
    EMAIL_FINANCEIRO: data.propriedades.EMAIL_FINANCEIRO,
    EMAIL_NFE: data.propriedades.EMAIL_NFE,
    LIMITE: data.propriedades.LIMITE,
    CLASSIFICACAO: data.propriedades.CLASSIFICACAO,
    NOME_ESTILISTA: data.propriedades.NOME_ESTILISTA,
    PARTICULARIDADES: data.propriedades.PARTICULARIDADES,
    PRAZOPAGAMENTO: data.propriedades.PRAZOPAGAMENTO,
    QTD_ATACADO: data.propriedades.QTD_ATACADO,
    QTD_VAREJO: data.propriedades.QTD_VAREJO,
    REDESPACHO: data.propriedades.REDESPACHO,
    REMPRESA1: data.propriedades.REMPRESA1,
    REMPRESA2: data.propriedades.REMPRESA2,
    REMPRESA3: data.propriedades.REMPRESA3,
    REMPRESAEMAIL1: data.propriedades.REMPRESAEMAIL1,
    REMPRESAEMAIL2: data.propriedades.REMPRESAEMAIL2,
    REMPRESAEMAIL3: data.propriedades.REMPRESAEMAIL3,
    REMPRESATELEFONE1: data.propriedades.REMPRESATELEFONE1,
    REMPRESATELEFONE2: data.propriedades.REMPRESATELEFONE2,
    REMPRESATELEFONE3: data.propriedades.REMPRESATELEFONE3,
    RESPONSAVEL_CONTASAPAGAR: data.propriedades.RESPONSAVEL_CONTASAPAGAR,
    RESPONSAVEL_FINANCEIRO: data.propriedades.RESPONSAVEL_FINANCEIRO,
    TELEFONECC1: data.propriedades.TELEFONECC1,
    TELEFONECC2: data.propriedades.TELEFONECC2,

    TELEFONEEMPRESA: data.propriedades.TELEFONEEMPRESA,
    TELEFONEFINANCEIRO: data.propriedades.TELEFONEFINANCEIRO,
    TELEFONECOMPRADOR: data.propriedades.TELEFONECOMPRADOR,
    TELEFONEESTILISTA: data.propriedades.TELEFONEESTILISTA,
    TELEFONECONTASAPAGAR: data.propriedades.TELEFONECONTASAPAGAR,

    TRANSPORTADORA: data.propriedades.TRANSPORTADORA,
    REDESPACHO: data.propriedades.REDESPACHO

  });
  //fields
  const [fields, setfields] = useState({
    ativa: data.ativa,
    bloqueada: data.bloqueada,
    cliente: data.cliente,
    fornecedor: data.fornecedor,
    vendedor: data.vendedor,
    transportadora: data.transportadora,
    funcionario: data.funcionario,
    tags: data.tags,
    tipo: data.tipo,
    nome: data.nome,
    apelido: data.apelido,
    cnpj: data.cnpj,
    inscricaoEstadual: data.inscricaoEstadual,
    cpf: data.cpf,
    rg: data.rg,
    enderecoLogradouro: data.enderecoLogradouro,
    enderecoNumero: data.enderecoNumero,
    enderecoComplemento: data.enderecoComplemento,
    enderecoBairro: data.enderecoBairro,
    enderecoCidade: data.enderecoCidade,
    enderecoEstado: data.enderecoEstado,
    enderecoPais: data.enderecoPais,
    enderecoCep: data.enderecoCep,
    observacoes: data.observacoes,
    vendedorPadrao: {
      id: null,
      nome: null
    }
  });

  const handleOpen = () => {
    setOpen(true);

    requisicao();
    loadFiles();
    loadCarriers();
    loadClientData();
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleChange(evt) {
    const value = evt.target.value.replaceAll("_", '');

    console.log('valor' + value + 'campo' + evt.target.id);
    setfields({
      ...fields,
      [evt.target.id]: value
    });
  }

  function handleChangeCustom(evt) {
    const value = evt.target.value.replaceAll("_", '');

    console.log('valor' + value + 'campo' + evt.target.id);
    setfieldsCustom({
      ...fieldsCustom,
      [evt.target.id.toUpperCase()]: value
    });
  }

  function handleCloseChild() {
    handleReqContatos();
    setisOpen(false);
  }


  useEffect(() => {
    (data.tipo === 'FISICA') ? setTipoRequired(false)
      : setTipoRequired(true)

  }, []);

  /*
    useEffect(() => {
      setfieldsCustom({
        ...fieldsCustom,
        'TRANSPORTADORA': idTransp,
        'REDESPACHO': idTranspRedespacho
      });
    }, [nomeTransp, nomeTranspRedespacho]);
  */
  const handleRequiretipo = e => {
    if (e === "FISICA") {
      setTipoRequired(false);
      setRequired(false);

      setfields({
        ...fields,
        'tipo': 'FISICA'
      });

    } else {
      setTipoRequired(true);
      setRequired("required");
      setfields({
        ...fields,
        'tipo': 'JURIDICA'
      });
    }
  };

  const handleRequireClassificacao = e => {
    setfields({
      ...fields,
      'classificacao': `${e}`
    });

  };

  const {
    id,
    ativa,
    bloqueada,
    tipo,
    nome,
    apelido,
    cnpj,
    inscricaoEstadual,
    inscricaoMunicipal,
    cpf,
    rg,
    enderecoLogradouro,
    enderecoNumero,
    enderecoComplemento,
    enderecoBairro,
    enderecoCidade,
    enderecoEstado,
    enderecoPais,
    enderecoCep,
    enderecoLatitude,
    enderecoLongitude,
    cliente,
    fornecedor,
    vendedor,
    transportadora,
    funcionario,
    segmentoDescricao,
    tags,
    observacoes,
    vendedorPadrao,
  } = data;
  // react-hooks-form
  const { register, getValues } = useForm();
  // Submit
  const handlePut = async (e) => {
    try {
      e.preventDefault();

      /*const {
        ativa,
        bloqueada,
        tipo,
        nome,
        apelido,
        cnpj,
        inscricaoEstadual,
        inscricaoMunicipal,
        cpf,
        rg,
        enderecoLogradouro,
        enderecoNumero,
        enderecoComplemento,
        enderecoBairro,
        enderecoCidade,
        enderecoEstado,
        enderecoPais,
        enderecoCep,
        enderecoLatitude,
        enderecoLongitude,
        cliente,
        fornecedor,
        vendedor,
        transportadora,
        funcionario,
        // segmentoDescricao,
        tags,
        observacoes,
        vendedorPadraoId,
        vendedorPadraoNome,
      } = data;
*/
      const dados = JSON.stringify({
        'ativa': fields.ativa,
        'bloqueada': fields.ativa,
        'tipo': fields.tipo,
        'nome': fields.nome,
        'apelido': fields.apelido,
        'cnpj': fields.cnpj,
        'inscricaoEstadual': fields.inscricaoEstadual,
        'cpf': fields.cpf,
        'rg': fields.rg,
        'enderecoLogradouro': logradouroApi,
        'enderecoNumero': fields.enderecoNumero,
        'enderecoComplemento': fields.enderecoComplemento,
        'enderecoBairro': bairroApi,
        'enderecoCidade': cidadeApi,
        'enderecoEstado': estadoApi,
        'enderecoPais': fields.enderecoPais,
        'enderecoCep': fields.enderecoCep,
        'observacoes': fields.observacoes,
        'cliente': fields.cliente,
        'fornecedor': fields.fornecedor,
        'vendedor': fields.vendedor,
        'transportadora': fields.transportadora,
        'funcionario': fields.funcionario,
        'tags': fields.tags,
        'vendedorPadrao': {
          'id': valueAutoId,
          'nome': valueAutoNome
        },
        'propriedades': fieldsCustom

      });

      let formData = new FormData();
      formData.append('dados', dados);

      for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i]);
      }

      const config = {
        headers: { "x-access-token": token, "dados": dados }
      }

      await axios.put(
        `${API.clientes}/${data.id}`,
        formData,
        config
      );

      toast.success("Atualizado com sucesso.");
      loadClients();
    } catch (err) {
      toast.error("Falha na atualização, verifique seus dados");
    }
  };

  // AutoComplete
  const [auto, setAuto] = useState([]);
  let value = vendedorPadrao === null ? '' : vendedorPadrao.nome;
  let valueId = vendedorPadrao === null ? '' : vendedorPadrao.id;
  const [valueAutoId, setValueAutoId] = useState(valueId);
  const [valueAutoNome, setValueAutoNome] = useState(value);
  const [contatos, setContatos] = useState([]);
  const [infoComerciais, setInfoComerciais] = useState([]);
  const [limite, setLimite] = useState('');
  const [reserva, setReserva] = useState('');
  const [limite_credito_saldo, setLimite_credito_saldo] = useState(0);
  const [limite_reserva_saldo, setLimite_reserva_saldo] = useState(0);
  const [pagamentosEfetuados, setPagamentosEfetuados] = useState('');
  const [titulosVencidos, setTituloVencidos] = useState('');
  const [titulosaVencer, setTituloaVencer] = useState('');
  const [prazo_medio, setPrazoMedio] = useState(0);
  const [atraso, setAtraso] = useState('');

  const [compras, setCompras] = useState('');

  function blobToFile(theBlob, fileName, ContentType) {
    const byteCharacters = atob(theBlob);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: ContentType });

    return new File([blob], fileName, { lastModified: new Date().getTime(), type: ContentType })
  }

  const loadClients = async () => {
    try {
      var toastId = null;
      if (toastId === null) {

        toastId = toast.info('Carregando dados do sistema, aguarde.', { progress: 15000 });
      }

      // 
      var where = `&cliente=true&bloqueada=false&ativa=true`;

      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            "x-access-token": token
          }
        }
      );

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = "";
      var v = ";"
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }
      sessionStorage.setItem('clientes', JSON.stringify(response.data.data));
      toast.done(toastId);
      window.location.reload();
      //  console.log(list_clients);

    } catch (err) {
      toast.done(toastId);
      toast.error("Nenhum cliente encontrado");

    }
  };
  const loadFiles = async () => {
    try {
      const response_files = await axios.get(`${API.cliente_files}/${data.id}`,
        {
          headers: {
            "x-access-token": token
          }
        }
      );

      if (response_files.data) {

        var new_files = [];
        response_files.data.map(file => {

          new_files.push(blobToFile(file.file, file.name, file.ContentType));
          //    new_files.push( new Blob([file.file], { name: file.name, type: file.ContentType }));
        })

        setFiles_saved(response_files.data ? new_files : "");
      }
    } catch (err) {


      setFiles_saved([]);
      //       setAuto([]);
    }
  };

  const loadClientData = async () => {
    try {
      const responseClient = await axios.get(`${API.clienteSQL}/${data.id}`, {
        headers: {
          'x-access-token': token,
        },
      });
      console.log('passando cliente ' + responseClient.data.data[0].PRAZO_PAGAMENTO);
      console.log(responseClient.data.data);
      setfieldsCustom({
        ...fieldsCustom,
        'PRAZOPAGAMENTO': responseClient.data.data[0] && responseClient.data.data[0].PRAZO_PAGAMENTO ? responseClient.data.data[0].PRAZO_PAGAMENTO : '',
        'TRANSPORTADORA': responseClient.data.data[0] && responseClient.data.data[0].TRANSPORTADORA_NOME ? responseClient.data.data[0].TRANSPORTADORA_NOME : '',
        'REDESPACHO': responseClient.data.data[0] && responseClient.data.data[0].REDESPACHO_NOME ? responseClient.data.data[0].REDESPACHO_NOME : '',
        'VENDEDOR': responseClient.data.data[0] && responseClient.data.data[0].VENDEDOR_APELIDO ? responseClient.data.data[0].VENDEDOR_APELIDO : ''
      });

    } catch (error) {
      console.log(error);
    }

  }

  const loadCarriers = async () => {
    try {
      const responseTransp = await axios.get(`${API.transportadoras}`, {
        headers: {
          'x-access-token': token,
        },
      });

      setautoTransp(
        responseTransp.data.data.map(item => {
          return {
            name: item.APELIDO + '(' + item.CGC + ')',
            id: item.NUMCAD1,
          };
        }),
      );
      setautoTranspRedespacho(
        responseTransp.data.data.map(item => {
          return {
            name: item.APELIDO + '(' + item.CGC + ')',
            id: item.NUMCAD1,
          };
        }),
      );
      if (data.propriedades.TRANSPORTADORA) {
        const transp = responseTransp.data.data.filter(function (obj) {
          if (Number(obj.NUMCAD1) === Number(data.propriedades.TRANSPORTADORA)) {
            return obj.APELIDO
          } else { return '' }
        })
        //console.log('transp'+ data.propriedades.TRANSPORTADORA);
        setNomeTransp(transp[0] ? transp[0].NOME : '');

      }
      if (data.propriedades.REDESPACHO) {
        const transpred = responseTransp.data.data.filter(function (obj) {
          if (Number(obj.NUMCAD1) === Number(data.propriedades.REDESPACHO)) {
            return obj.APELIDO
          } else { return '' }
        })
        //console.log('transp'+ data.propriedades.TRANSPORTADORA);
        setNomeTranspRedespacho(transpred[0] ? transpred[0].NOME : '');

      }

    } catch (err) {
      if (err.response && err.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login');
        sessionStorage.clear();
      } else {
        //toast.error('Erro ao carregar lista de transportadoras');
      }
    }
  };




  const requisicao = async () => {
    try {
      const response = await axios.get(`${API.vendedores}?email=${email}`, {
        headers: {
          'x-access-token': token,
        },
      });

      const list = response.data.data;
      setAuto(list);
    } catch (err) {
      toast.error('Houve um erro ao carregar a lista de vendedores.');
      setAuto([]);
    }
  };

  async function handleReqContatos() {
    try {
      const response2 = await axios.get(`${API.clientes}/${data.id}/contatos`, {
        headers: {
          'x-access-token': token,
        },
      });

      const contact = response2.data.data;

      setContatos(contact);
    } catch (err) {
      //  toast.error("Houve um erro ao listar contatos.")
      setContatos([]);
    }
  }

  async function handleReqPedidos() {
    setPedidosOpen(true);
  }

  async function handleReqInfoComerciais() {
    try {
      const response = await axios.get(
        `${API.infocomerciais}?conta_cliente=${data.id}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );

      const dadoscomerciais = response.data.data;

      setInfoComerciais(dadoscomerciais[0]);

      let primeira_compra_valor = dadoscomerciais[0].IC_PRIMEIRA_COMPRA_VALOR.toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        },
      );

      let ultima_compra_valor = dadoscomerciais[0].IC_ULTIMA_COMPRA_VALOR.toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        },
      );

      let maior_compra_valor = dadoscomerciais[0].IC_MAIOR_COMPRA_VALOR.toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        },
      );

      let primeira_compra_data = moment(
        dadoscomerciais[0].IC_PRIMEIRA_COMPRA_DATA,
      ).format('DD/MM/YYYY');

      let ultima_compra_data = moment(
        dadoscomerciais[0].IC_ULTIMA_COMPRA_DATA,
      ).format('DD/MM/YYYY');

      let maior_compra_data = moment(
        dadoscomerciais[0].IC_MAIOR_COMPRA_DATA,
      ).format('DD/MM/YYYY');

      setPrazoMedio([
        [
          'Element',
          'Dias',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
        [
          'Prazo Médio',
          Number(dadoscomerciais[0].IC_PRAZO_MEDIO),
          'blue',
          Number(dadoscomerciais[0].IC_PRAZO_MEDIO) + ' DIAS',
        ],
        ['', null, 'red', null],
      ]);

      setLimite_credito_saldo(
        Number(dadoscomerciais[0].IC_CREDITO_DISPONIVEL).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
          },
        ),
      );
      setLimite_reserva_saldo(
        Number(dadoscomerciais[0].IC_RESERVA_DISPONIVEL).toLocaleString(
          'pt-BR',
          {
            style: 'currency',
            currency: 'BRL',
          },
        ),
      );

      //Dados do gráfico
      setLimite([
        [
          'Element',
          'Valor',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
        [
          'Limite',
          Number(dadoscomerciais[0].IC_CREDITO_LIMITE),
          '#b87333',
          Number(dadoscomerciais[0].IC_CREDITO_LIMITE).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        ],
        [
          'Utilizado',
          Number(dadoscomerciais[0].IC_CREDITO_UTILIZADO),
          'silver',
          Number(dadoscomerciais[0].IC_CREDITO_UTILIZADO).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
        ],
      ]);

      setAtraso([
        [
          'Element',
          'Dias',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
        [
          'Atraso Médio',
          Number(dadoscomerciais[0].PE_ATRASO_MEDIO),
          'blue',
          Number(dadoscomerciais[0].PE_ATRASO_MEDIO),
        ],
        [
          'Maior Atraso',
          Number(dadoscomerciais[0].PE_MAIOR_ATRASO),
          'red',
          Number(dadoscomerciais[0].PE_MAIOR_ATRASO),
        ],
      ]);

      setReserva([
        [
          'Element',
          'Valor',
          { role: 'style' },
          {
            sourceColumn: 0,
            role: 'annotation',
            type: 'string',
            calc: 'stringify',
          },
        ],
        [
          'Limite',
          Number(dadoscomerciais[0].IC_RESERVA_LIMITE),
          '#b87333',
          Number(dadoscomerciais[0].IC_RESERVA_LIMITE).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        ],
        [
          'Utilizado',
          Number(dadoscomerciais[0].IC_RESERVA_UTILIZADA),
          'red',
          Number(dadoscomerciais[0].IC_RESERVA_UTILIZADA).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
        ],
      ]);
      setPagamentosEfetuados([
        ['Pagamentos efetuados', 'Valor R$', 'Títulos'],
        [
          'Em dia',
          Number(dadoscomerciais[0].PE_EM_DIA_VALOR).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].PE_EM_DIA_TITULOS),
        ],
        [
          'Após  o vencimento',
          Number(dadoscomerciais[0].PE_APOS_VENCIMENTO_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].PE_APOS_VENCIMENTO_TITULOS),
        ],
        [
          'Em cartório',
          Number(dadoscomerciais[0].PE_CARTORIO_VALOR).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].PE_CARTORIO_TITULOS),
        ],
        [
          'Em protesto',
          Number(dadoscomerciais[0].PE_PROTESTO_VALOR).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].PE_PROTESTO_TITULOS),
        ],
      ]);

      setTituloVencidos([
        ['Títulos vencidos', 'Valor R$', 'Títulos'],
        [
          'Títulos vencidos - Total',
          Number(dadoscomerciais[0].VI_TOTAL).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].VI_TITULOS),
        ],
        [
          'Títulos vencidos até 7 dias',
          Number(dadoscomerciais[0].VI_ATE_07_DIAS_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].VI_ATE_07_DIAS_TITULOS),
        ],
        [
          'Títulos vencidos a mais de 7 dias',
          Number(dadoscomerciais[0].VI_ACIMA_07_DIAS_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].VI_ACIMA_07_DIAS_TITULOS),
        ],
        [
          'Em cartório',
          Number(dadoscomerciais[0].VI_CARTORIO_VALOR).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].VI_CARTORIO_TITULOS),
        ],
        [
          'Em protesto',
          Number(dadoscomerciais[0].VI_PROTESTO_VALOR).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].VI_PROTESTO_TITULOS),
        ],
      ]);

      setTituloaVencer([
        ['Títulos a vencer', 'Valor R$', 'Títulos'],
        [
          'Títulos a vencer - Total',
          Number(dadoscomerciais[0].VE_TOTAL).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          Number(dadoscomerciais[0].VE_TITULOS),
        ],
        [
          'Títulos a vencer em até 30 dias',
          Number(dadoscomerciais[0].VE_ATE_30_DIAS_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].VE_ATE_30_DIAS_TITULOS),
        ],
        [
          'Títulos a vencer entre 30 e 60 dias',
          Number(dadoscomerciais[0].VE_30_60_DIAS_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].VE_30_60_DIAS_TITULOS),
        ],
        [
          'Títulos a vencer entre 60 e 90 dias',
          Number(dadoscomerciais[0].VE_60_90_DIAS_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].VE_60_90_DIAS_TITULOS),
        ],
        [
          'Títulos a vencer acima de 90 dias',
          Number(dadoscomerciais[0].VE_ACIMA_90_DIAS_VALOR).toLocaleString(
            'pt-BR',
            {
              style: 'currency',
              currency: 'BRL',
            },
          ),
          Number(dadoscomerciais[0].VE_ACIMA_90_DIAS_TITULOS),
        ],
      ]);

      setCompras([
        ['id', 'childLabel', 'parent', 'size', { role: 'style' }],
        [0, 'Compras', -1, 2, 'black'],
        [1, 'Primeira', 0, 3, 'black'],
        [2, 'Última', 0, 5, 'black'],
        [3, 'Maior', 0, 1, 'black'],
        [4, `Data:${primeira_compra_data}`, 1, 2, 'black'],
        [5, `Valor: ${primeira_compra_valor}`, 1, 2, 'black'],
        [6, `Data:${ultima_compra_data}`, 2, 2, 'black'],
        [7, `Valor:${ultima_compra_valor}`, 2, 2, 'black'],
        [8, `Data:${maior_compra_data}`, 3, 2, 'black'],
        [9, `Valor:${maior_compra_valor}`, 3, 2, 'black'],
      ]);

      //   setContatos(contact);
    } catch (err) {
      //  toast.error("Houve um erro ao listar contatos.");
    }
  }



  function createData(
    tipo,
    descricao,
    complemento
  ) {
    return {
      tipo,
      descricao,
      complemento
    };
  }


  const rowHead = [
    {
      title: 'Tipo',
      field: 'tipo',
      cellStyle: {
        fontSize: "12px"
      }
    },
    {
      title: 'Descrição',
      field: 'descricao',
      cellStyle: {
        fontSize: "12px"
      }
    },
    {
      title: 'Complemento',
      field: 'complemento',
      cellStyle: {
        fontSize: "12px"
      }
    }
  ];

  var rowContatos = contatos
    ? contatos.map(item => {
      const { tipo, descricao, complemento } = item;
      const row = createData(tipo,
        descricao,
        complemento
      )

      return row;
    })
    : [{ error: 'Não encontrado' }];





  const [logradouroApi, setLogradouro] = useState(enderecoLogradouro);
  const [cidadeApi, setCidade] = useState(enderecoCidade);
  const [bairroApi, setBairro] = useState(enderecoBairro);
  const [estadoApi, setEstado] = useState(enderecoEstado);
  const [complementoApi, setComplemento] = useState(enderecoComplemento);
  // Cep
  const handleCep = async e => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json`,
      );
      const {
        logradouro: logradouroApi,
        bairro: bairroApi,
        uf: ufApi,
        localidade: localidadeApi,
        complemento: complementoApi,
      } = response.data;

      setLogradouro(logradouroApi);
      setBairro(bairroApi);
      setEstado(ufApi);
      setCidade(localidadeApi);
      setComplemento(complementoApi);
    } catch (err) {
      toast.error('Cep inválido.');
    }
  };

  // Selects
  return (
    <div>
      <button className={classes.button} type="button" onClick={handleOpen}>
        <EditIcon />
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Tabs>
              <TabList>
                <Tab>Conta</Tab>

                <Tab onClick={() => handleReqContatos()}>Contatos</Tab>

                <Tab onClick={() => handleReqInfoComerciais()}>
                  Informações comerciais e financeiras
                </Tab>
                <Tab onClick={() => handleReqPedidos()}>
                  Pedidos
                </Tab>
              </TabList>

              <TabPanel>


                <form onSubmit={handlePut} enctype="multipart/form-data" >
                  <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", paddingBottom: "20px" }}>
                    <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                      <input
                        type="hidden"
                        ref={register}
                        defaultValue={id}
                        name="id"
                      />

                      <Grid item xs={12} sm={12} lg={12}>
                        <center><h5>CLIENTE</h5></center>
                        <FormControlLabel disabled control={<Checkbox defaultChecked={fields.ativa} name="ativa" />} label="Ativa" />
                        <FormControlLabel disabled control={<Checkbox defaultChecked={fields.bloqueada} name="bloqueada" />} label="Bloqueada" />
                        <FormControlLabel disabled control={<Checkbox defaultChecked={fields.cliente} name="cliente" />} label="Cliente" />

                      </Grid>


                      <Grid item xs={12} sm={12} lg={4}>


                        <TextField
                          select
                          label="Tipo"
                          name="tipo"
                          value={fields.tipo}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="required"

                          onChange={e => handleRequiretipo(e.target.value)}
                        >
                          <MenuItem value={"JURIDICA"}>JURIDICO</MenuItem>
                          <MenuItem value={"FISICA"}>FISICA</MenuItem>
                        </TextField>

                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          id="nome"
                          label="Nome"
                          type="text"
                          onChange={handleChange}
                          value={fields.nome}
                          style={{ width: "100%" }}

                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          id="apelido"
                          label="Apelido"
                          type="text"
                          value={fields.apelido}
                          style={{ width: "100%" }}
                          required="required"
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                      </Grid>


                      {tipoRequired ? (
                        <>
                          <Grid item xs={12} sm={12} lg={4}>
                            <InputMask mask="99.999.999/9999-99"
                              onChange={handleChange}
                              value={fields.cnpj}
                            >
                              <TextField
                                id="cnpj"
                                label="Cnpj"
                                name="cnpj"

                                type="text"
                                style={{ width: "100%" }}
                                required={(required) ? required : "required"}
                                InputLabelProps={{
                                  shrink: true,
                                }}

                              />
                            </InputMask>

                          </Grid>
                          <Grid item xs={12} sm={12} lg={4}>
                              <TextField
                                id="inscricaoEstadual"
                                label="Inscrição Estadual"
                                name="inscricaoEstadual"
                                type="text"
                                value={fields.inscricaoEstadual}
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}

                                required={(required) ? required : "required"}

                              />
                            
                          </Grid>

                        </>
                      ) : (
                          <>
                            <Grid item xs={12} sm={12} lg={4}>
                              <InputMask mask="999.999.999-99"
                                onChange={handleChange}
                                value={fields.cpf}
                              >
                                <TextField
                                  label="CPF"
                                  name="cpf"
                                  id="cpf"
                                  type="text"

                                  required="required"
                                  style={{ width: "100%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}

                                />
                              </InputMask>
                            </Grid>

                            <Grid item xs={12} sm={12} lg={4}>

                              <TextField
                                label="RG"
                                name="rg"
                                id="rg"
                                type="text"
                                required="required"
                                value={fields.rg}

                                onChange={handleChange}
                                style={{ width: "100%" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}

                              />
                            </Grid>

                          </>
                        )}

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          id="enderecoCep"
                          label="CEP"
                          name="enderecoCep"
                          type="text"
                          value={fields.enderecoCep}

                          required="required"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          onBlur={handleCep}

                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Endereço"
                          id="enderecoLogradouro"
                          name="enderecoLogradouro"
                          type="text"
                          required="required"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={logradouroApi}
                          onChange={e => setLogradouro(e.target.value)}

                        />

                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Número"
                          id="enderecoNumero"
                          name="enderecoNumero"
                          required="required"
                          value={fields.enderecoNumero}
                          type="text"
                          onChange={handleChange}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Complemento"
                          id="enderecoComplemento"
                          name="enderecoComplemento"
                          type="text"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={complementoApi ? complementoApi : ""}
                          onChange={e => setComplemento(e.target.value)}

                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Bairro"
                          id="enderecoBairro"
                          name="enderecoBairro"
                          type="text"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}

                          required="required"
                          value={bairroApi ? bairroApi : ""}
                          onChange={e => setBairro(e.target.value)}

                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          id="enderecoCidade"
                          label="Cidade"
                          name="enderecoCidade"
                          type="text"
                          style={{ width: "100%" }}



                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="required"
                          value={cidadeApi ? cidadeApi : ""}
                          onChange={e => setCidade(e.target.value)}

                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          id="enderecoEstado"
                          label="Estado"
                          name="enderecoEstado"
                          type="text"

                          required="required"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={estadoApi ? estadoApi : ""}
                          onChange={e => setEstado(e.target.value)}

                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="País"
                          id="enderecoPais"
                          name="enderecoPais"
                          required="required"
                          defaultValue="Brasil"
                          type="text"
                          onChange={handleChange}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>

                        <TextField
                          label="Prazo Pagamento"
                          id="prazoPagamento"
                          name="prazoPagamento"
                          type="text"
                          value={fieldsCustom.PRAZOPAGAMENTO}

                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>

                        <TextField
                          label="Vendedor"
                          id="vendedor"
                          name="vendedor"
                          type="text"
                          value={fieldsCustom.VENDEDOR}

                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>

                        <TextField
                          label="Transportadora"
                          id="transportadora"
                          name="transportadora"
                          type="text"
                          value={fieldsCustom.TRANSPORTADORA}
                          defaultValue={fieldsCustom.TRANSPORTADORA}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>

                        <TextField
                          label="Redespacho"
                          id="redespacho"
                          name="redespacho"
                          type="text"
                          value={fieldsCustom.REDESPACHO}
                          defaultValue={fieldsCustom.REDESPACHO}

                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                    </Grid>

                  </Box>
                  {/*
                  <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", paddingBottom: "20px" }}>
                    <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>

                      <Grid item xs={12} sm={12} lg={12}>
                        <center><h5>Contatos </h5></center>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email da empresa"
                          id="EMAIL"
                          name="EMAIL"
                          value={fieldsCustom.EMAIL}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email pra envio de nota fiscal"
                          id="EMAIL_NFE"
                          name="EMAIL_NFE"
                          value={fieldsCustom.EMAIL_NFE}
                          required="required"
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.TELEFONEEMPRESA}
                        >
                          <TextField
                            label="Telefone"
                            id="TELEFONEEMPRESA"
                            name="TELEFONEEMPRESA"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Responsável pelo financeiro"
                          id="RESPONSAVEL_FINANCEIRO"
                          name="RESPONSAVEL_FINANCEIRO"
                          type="text"
                          value={fieldsCustom.RESPONSAVEL_FINANCEIRO}
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email do responsável pelo financeiro"
                          id="EMAIL_FINANCEIRO"
                          name="EMAIL_FINANCEIRO"
                          value={fieldsCustom.EMAIL_FINANCEIRO}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.TELEFONEFINANCEIRO}
                        >
                          <TextField
                            label="Telefone"
                            id="TELEFONEFINANCEIRO"
                            name="TELEFONEFINANCEIRO"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Nome do comprador"
                          id="COMPRADOR"
                          name="COMPRADOR"
                          value={fieldsCustom.COMPRADOR}
                          required="required"
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email do comprador"
                          id="EMAIL_COMPRADOR"
                          name="EMAIL_COMPRADOR"
                          value={fieldsCustom.EMAIL_COMPRADOR}
                          type="text"
                          required="required"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.TELEFONECOMPRADOR}
                        >
                          <TextField
                            label="Telefone"
                            id="TELEFONECOMPRADOR"
                            name="TELEFONECOMPRADOR"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Nome do estilista"
                          id="NOME_ESTILISTA"
                          name="NOME_ESTILISTA"
                          value={fieldsCustom.NOME_ESTILISTA}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email do estilista"
                          id="EMAIL_ESTILISTA"
                          name="EMAIL_ESTILISTA"
                          value={fieldsCustom.EMAIL_ESTILISTA}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.TELEFONEESTILISTA}
                        >
                          <TextField
                            label="Telefone"
                            id="TELEFONEESTILISTA"
                            name="TELEFONEESTILISTA"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Nome responsável por contas a pagar"
                          id="RESPONSAVEL_CONTASAPAGAR"
                          name="RESPONSAVEL_CONTASAPAGAR"
                          value={fieldsCustom.RESPONSAVEL_CONTASAPAGAR}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email do responsável por contas a pagar"
                          id="EMAIL_CONTASAPAGAR"
                          name="EMAIL_CONTASAPAGAR"
                          value={fieldsCustom.EMAIL_CONTASAPAGAR}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.TELEFONECONTASAPAGAR}
                        >
                          <TextField
                            label="Telefone"
                            id="TELEFONECONTASAPAGAR"
                            name="TELEFONECONTASAPAGAR"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>

                    </Grid>

                  </Box>
                          */}
                  <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", marginBottom: "20px" }}>
                    <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <center><h5>Referências comerciais </h5></center>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Empresa"
                          id="Rempresa1"
                          name="Rempresa1"
                          value={fieldsCustom.REMPRESA1}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email"
                          id="RempresaEmail1"
                          name="RempresaEmail1"
                          value={fieldsCustom.REMPRESAEMAIL1}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.REMPRESATELEFONE1}
                        >
                          <TextField
                            label="Telefone"
                            id="RempresaTelefone1"
                            name="RempresaTelefone1"

                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Empresa"
                          id="Rempresa2"
                          name="Rempresa2"
                          value={fieldsCustom.REMPRESA2}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email"
                          id="RempresaEmail2"
                          name="RempresaEmail2"
                          value={fieldsCustom.REMPRESAEMAIL2}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.REMPRESATELEFONE2}
                        >
                          <TextField
                            label="Telefone"
                            id="RempresaTelefone2"
                            name="RempresaTelefone2"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Empresa"
                          id="Rempresa3"
                          name="Rempresa3"
                          value={fieldsCustom.REMPRESA3}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Email"
                          id="RempresaEmail3"
                          name="RempresaEmail3"
                          value={fieldsCustom.REMPRESAEMAIL3}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.REMPRESATELEFONE3}
                        >
                          <TextField
                            label="Telefone"
                            id="RempresaTelefone3"
                            name="RempresaTelefone3"

                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>

                      </Grid>
                    </Grid>
                  </Box>
                  <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", marginBottom: "20px" }}>
                    <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <center><h5>Referências Bancárias </h5></center>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={2}>
                        <TextField
                          label="Banco"
                          id="banco1"
                          name="banco1"
                          type="text"
                          value={fieldsCustom.BANCO1}
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={2}>
                        <TextField
                          label="Agência"
                          id="agencia1"
                          name="agencia1"
                          value={fieldsCustom.AGENCIA1}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        <TextField
                          label="Conta corrente"
                          id="contacorrente1"
                          name="contacorrente1"
                          value={fieldsCustom.CONTACORRENTE1}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        <TextField
                          label="Contato"
                          id="contatocc1"
                          name="contatocc1"
                          value={fieldsCustom.CONTATOCC1}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={2}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          value={fieldsCustom.TELEFONECC1}
                          onChange={handleChangeCustom}>
                          <TextField
                            label="Telefone"
                            id="telefonecc1"
                            name="telefonecc1"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={2}>
                        <TextField
                          label="Banco"
                          id="banco2"
                          name="banco2"
                          value={fieldsCustom.BANCO2}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={2}>
                        <TextField
                          label="Agência"
                          id="agencia2"
                          name="agencia2"
                          value={fieldsCustom.AGENCIA2}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        <TextField
                          label="Conta corrente"
                          id="contacorrente2"
                          name="contacorrente2"
                          type="text"
                          value={fieldsCustom.CONTACORRENTE2}
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={3}>
                        <TextField
                          label="Contato"
                          id="contatocc2"
                          name="contatocc2"
                          value={fieldsCustom.CONTATOCC2}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={2}>
                        <InputMask mask="(99)999999999"
                          maskPlaceholder=""
                          onChange={handleChangeCustom}
                          value={fieldsCustom.TELEFONECC2}
                        >
                          <TextField
                            label="Telefone"
                            id="telefonecc2"
                            name="telefonecc2"
                            type="text"
                            onChange={handleChangeCustom}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </InputMask>
                      </Grid>

                    </Grid>
                  </Box>
                  <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", marginBottom: "20px" }}>
                    <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <center><h5>Particularidades</h5></center>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={12}>
                        <label>Como conheceu a empresa, conhece o propietário, descreva a loja fisicamente, local, estrutura, porte, funcionários, etc</label>
                        <TextField

                          id="particularidades"
                          name="particularidades"
                          value={fieldsCustom.PARTICULARIDADES}
                          multiline
                          rows={3}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true

                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <TextField
                          label="Quantas lojas no varejo?"
                          id="qtd_varejo"
                          name="qtd_varejo"
                          value={fieldsCustom.QTD_VAREJO}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={6}>
                        <TextField
                          label="Quantas lojas no atacado?"
                          id="qtd_atacado"
                          name="qtd_atacado"
                          value={fieldsCustom.QTD_ATACADO}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={12}>
                        <label>Cliente é antenado com as tendências e novidades do mercado, é adequado para trabalhar com os nossos produtos, como se posiciona no mercado, etc?</label>
                        <TextField
                          id="cliente_tendecias"
                          name="cliente_tendecias"
                          value={fieldsCustom.CLIENTE_TENDECIAS}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ position: "relative", width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          label="Qual a sua sugestão de limite?"
                          id="limite"
                          name="limite"
                          value={fieldsCustom.LIMITE}
                          type="text"
                          onChange={handleChangeCustom}
                          style={{ position: 'relative' }}

                          InputLabelProps={{
                            shrink: true,
                            position: 'relative'
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>

                        <TextField
                          select
                          label="Como você classificaria o cliente?"
                          name="classificacao"
                          id="classificacao"
                          value={fieldsCustom.CLASSIFICACAO}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required="required"

                          onChange={e => handleRequireClassificacao(e.target.value)}
                        >
                          <MenuItem value={"OTIMO"}>ÓTIMO</MenuItem>
                          <MenuItem value={"BOM"}>BOM</MenuItem>
                          <MenuItem value={"REGULAR"}>REGULAR</MenuItem>
                        </TextField>
                      </Grid>


                    </Grid>
                  </Box>
                  <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
                    <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>

                      <Grid item xs={12} sm={12} lg={12}>
                        <center><h5>Arquivos </h5></center>
                      </Grid>

                      <Grid item xs={12} sm={12} lg={12}>
                        <div className="content" style={{ height: "auto", margin: "10px", paddingBottom: "20px" }}>
                          {files_saved &&
                            <Dropzone id={data.id} edit={true} files_svd={files_saved} parentFiles={setFiles} />
                          }
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  {/*    <ButtonStyled variant="contained" color="primary">
                    Atualizar
              </ButtonStyled>
                        */}
                </form>

              </TabPanel>
              <TabPanel>

                <DataTable
                  rows={rowContatos}
                  rowHead={rowHead}
                  title={'Contatos'}
                />
              </TabPanel>
              <TabPanel>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                          <Icon>trending_up</Icon>
                        </CardIcon>
                        <p
                          className={classes.cardCategory}
                          style={{
                            color: 'black',
                          }}
                        >
                          Limite de crédito (Saldo)
                        </p>
                        <h3
                          className={classes.cardTitle}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            color: 'black',
                          }}
                        >
                          {limite_credito_saldo}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Danger></Danger>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <Store />
                        </CardIcon>
                        <p
                          className={classes.cardCategory}
                          style={{
                            color: 'black',
                          }}
                        >
                          Reserva Disponível (Saldo)
                        </p>
                        <h3
                          className={classes.cardTitle}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            color: 'black',
                          }}
                        >
                          {limite_reserva_saldo}
                        </h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Danger></Danger>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={auto}
                        height={'250px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={limite}
                        options={{
                          is3D: true,

                          title: 'Limite de crédito',
                          titlePosition: 'out',
                          axisTitlesPosition: 'out',
                          hAxis: { textPosition: 'out' },
                          vAxis: { textPosition: 'out' },

                          width: auto,
                          height: auto,
                          bar: { groupWidth: '45%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'250px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={reserva}
                        options={{
                          is3D: true,

                          title: 'Limite de reserva',
                          width: auto,
                          height: auto,
                          bar: { groupWidth: '45%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'250px'}
                        chartType="WordTree"
                        loader={<div>Carregando gráfico</div>}
                        data={compras}
                        options={{
                          maxFontSize: 12,
                          enableInteractivity: false,
                          tooltip: { trigger: 'none' },
                          chartArea: { width: '100%' },
                          width: auto,
                          height: auto,
                          colors: ['black', 'black', 'black'],

                          wordtree: {
                            format: 'explicit',
                            type: 'suffix',
                          },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'250px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={prazo_medio}
                        options={{
                          is3D: true,
                          title: 'Prazo Médio das compras',
                          width: auto,
                          height: auto,
                          bar: { groupWidth: '65%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <Icon>attach_money</Icon>
                        </CardIcon>
                        <p
                          className={classes.cardCategory}
                          style={{
                            color: 'black',
                          }}
                        >
                          Informações Financeiras
                        </p>
                        <h3
                          className={classes.cardTitle}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            color: 'black',
                          }}
                        ></h3>
                      </CardHeader>
                      <CardFooter stats>
                        <div className={classes.stats}>
                          <Danger></Danger>
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'300px'}
                        chartType="Table"
                        loader={<div>Carregando gráfico</div>}
                        data={pagamentosEfetuados}
                        formatters={[
                          {
                            type: 'BarFormat',
                            column: 1,
                            options: {
                              width: 120,
                            },
                          },
                          {
                            type: 'ArrowFormat',
                            column: 2,
                          },
                        ]}
                        options={{
                          allowHtml: true,
                          showRowNumber: true,
                          width: '100%',
                          height: '100%',
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={auto}
                        height={'300px'}
                        chartType="BarChart"
                        loader={<div>Carregando gráfico</div>}
                        data={atraso}
                        options={{
                          is3D: true,

                          title: 'Atrasos',
                          titlePosition: 'out',
                          axisTitlesPosition: 'out',
                          hAxis: { textPosition: 'out' },
                          vAxis: { textPosition: 'out' },

                          width: auto,
                          height: auto,
                          bar: { groupWidth: '45%' },
                          legend: { position: 'none' },
                        }}
                        // For tests
                        rootProps={{ 'data-testid': '6' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'300px'}
                        chartType="Table"
                        loader={<div>Carregando gráfico</div>}
                        data={titulosVencidos}
                        formatters={[
                          {
                            type: 'BarFormat',
                            column: 1,
                            options: {
                              width: 120,
                            },
                          },
                          {
                            type: 'ArrowFormat',
                            column: 2,
                          },
                        ]}
                        options={{
                          allowHtml: true,
                          showRowNumber: true,
                          width: '100%',
                          height: '100%',
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card>
                      <Chart
                        width={'auto'}
                        height={'300px'}
                        chartType="Table"
                        loader={<div>Carregando gráfico</div>}
                        data={titulosaVencer}
                        formatters={[
                          {
                            type: 'BarFormat',
                            column: 1,
                            options: {
                              width: 120,
                            },
                          },
                          {
                            type: 'ArrowFormat',
                            column: 2,
                          },
                        ]}
                        options={{
                          allowHtml: true,
                          showRowNumber: true,
                          width: '100%',
                          height: '100%',
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </Card>
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel>
                {
                  pedidosOpen && (
                    <Pedidos Client={data.id} />
                  )
                }
              </TabPanel>
            </Tabs>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

ModalClientes.propTypes = {
  data: PropTypes.string.isRequired,
};
