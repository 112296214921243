import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import InputMask from "react-input-mask";
import Autocomplete from "react-autocomplete";
import { toast } from "react-toastify";
import { Form, ButtonStyled } from "./styles";
import { API } from "../../../config/api"
import { BottomModal } from 'react-spring-modal';
import 'react-spring-modal/dist/index.css';
import TextField from '@material-ui/core/TextField';

import Box from '@material-ui/core/Box';
import { Div } from "views/Produtos/stylesFinaliza";
import MenuItem from '@material-ui/core/MenuItem';
import { useForm, Controller } from "react-hook-form";
import Upload from "../../Upload";
import FileList from "../../FileList";
import { uniqueId } from "lodash";
import filesize from "filesize";
import Dropzone from "../../Upload";
import Select from 'react-select';




import createGlobalStyle from "../../../styles/global";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: "#f8f9fc",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "80%",
    maxHeight: "80%",
    overflow: "scroll"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  button: {
    border: 0,
    borderRadius: "20px",
    backgroundColor: "#00c156",
    color: "#fff",
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    marginLeft: "auto",
    marginRight: "15px"
  },
  btnAdd: {
    border: 0,
    borderRadius: "20px",
    backgroundColor: "#00acc1",
    color: "#fff",
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    marginLeft: "auto",
    marginRight: "15px",
    alignItems: "center",
    fontSize:"15px"
  },

  label: {
    position: 'relative'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(3),
      minWidth: '100%',
      minWidth: '100%',
      width: '25ch',

    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    label: {
      position: 'relative'
    },
    '& .MuiInputLabel-formControl': {

      position: 'relative',

    },

  },
}));

export default function ModalCreate() {
  // Token
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const { formRef } = useRef(null);

  // Modal
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [required, setRequired] = React.useState(false);
  // AutoComplete
  const [auto, setAuto] = useState();
  let value = "";
  const [valueAutoId, setValueAutoId] = useState(value);
  const [valueAutoNome, setValueAutoNome] = useState(value);
  const [logradouroApi, setLogradouro] = useState("");
  const [cidadeApi, setCidade] = useState("");
  const [bairroApi, setBairro] = useState("");
  const [estadoApi, setEstado] = useState("");
  const [complementoApi, setComplemento] = useState("");
  const [upload, setUpload] = useState("");
  const [files, setFiles] = useState("");
  const [mascara, setMascara] = React.useState(null);
  const [autoTransp, setautoTransp] = useState([]);
  const [autoTranspRedespacho, setautoTranspRedespacho] = useState('');
  const [nomeTransp, setNomeTransp] = useState('');
  const [nomeTranspRedespacho, setNomeTranspRedespacho] = useState('');
  const [idTransp, setIdTransp] = useState('');
  const [idTranspRedespacho, setIdTranspRedespacho] = useState('');
  const [files_saved, setFiles_saved] = useState("");
  const [przPagamento, setprzPagamento] = useState([]);
  const [loading, setLoading] = useState(false);



  const callbackfiles = (childData) => {
    setFiles(childData);
    console.log(files);
  };


  const [fieldsCustom, setfieldsCustom] = useState([]);
  //fields
  const [fields, setfields] = useState({
    ativa: true,
    bloqueada: false,
    tipo: null,
    nome: null,
    apelido: null,
    cnpj: null,
    inscricaoEstadual: null,
    cpf: null,
    rg: null,
    enderecoLogradouro: null,
    enderecoNumero: null,
    enderecoComplemento: null,
    enderecoBairro: null,
    enderecoCidade: null,
    enderecoEstado: null,
    enderecoPais: 'Brasil',
    enderecoCep: null,
    observacoes: null,
    vendedorPadrao: {
      id: null,
      nome: null
    }
  });


  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const handleChangeFiles = () => {

  }
  const handleUpload = (files) => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }));

    setUploadedFiles(this.state.uploadedFiles.concat(uploadedFiles)
    );

    uploadedFiles.forEach(this.processUpload);

  }
  const handleDelete = () => {

  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handleChange(evt) {
    const value = evt.target.value.replaceAll("_", '');

    console.log('valor' + value + 'campo' + evt.target.id);
    setfields({
      ...fields,
      [evt.target.id]: value
    });
  }

  function handleChangeCustom(evt) {
    const value = evt.target.value.replaceAll("_", '');

    //console.log('valor'+value+'campo'+ evt.target.id);
    setfieldsCustom({
      ...fieldsCustom,
      [evt.target.id.toUpperCase()]: value
    });
  }

  const handleRequireClassificacao = e => {
    setfieldsCustom({
      ...fieldsCustom,
      'CLASSIFICACAO': `${e}`
    });

  };

  // react-hooks-form
  const { register } = useForm();

  // Tipo Required
  const [tipoRequired, setTipoRequired] = useState(true);

  const handleRequiretipo = e => {
    if (e === "FISICA") {
      setTipoRequired(false);
      setRequired(false);

      setfields({
        ...fields,
        'tipo': 'FISICA'
      });

    } else {
      setTipoRequired(true);
      setRequired("required");
      setfields({
        ...fields,
        'tipo': 'JURIDICA'
      });
    }
  };

  const loadClients = async () => {
    try {
      var toastId = null;
      if (toastId === null) {

        toastId = toast.info('Carregando dados do sistema, aguarde.', { progress: 15000 });
      }

      // 
      var where = `&cliente=true&bloqueada=false&ativa=true`;

      const response = await axios.get(
        `${API.clientes}?email=${email}${where}`,
        {
          headers: {
            "x-access-token": token
          }
        }
      );

      //função que troca null por "" para evitar erro em tela para objeto nulo .
      var k = "";
      var v = ";"
      for (const obj of response.data.data) {
        if (typeof obj !== 'object') continue;
        for (k in obj) {
          if (!obj.hasOwnProperty(k)) continue;
          v = obj[k];
          if (v === null || v === undefined) {
            obj[k] = '';
          }
        }
      }
      sessionStorage.setItem('clientes', JSON.stringify(response.data.data));
      toast.done(toastId);
      window.location.reload();
      //  console.log(list_clients);

    } catch (err) {
      toast.done(toastId);
      toast.error("Nenhum cliente encontrado");

    }
  };
  // Submit
  const handleSubmit = async (e) => {
    e.preventDefault();


    /* console.log( {
       'ativa':fields.ativa,
       'bloqueada':fields.ativa,
       'tipo':fields.tipo,
       'nome':fields.nome,
       'apelido':fields.apelido,
       'cnpj':fields.cnpj,
       'inscricaoEstadual':fields.inscricaoEstadual,
       'cpf':fields.cpf,
       'rg':fields.rg,
       'enderecoLogradouro':logradouroApi,
       'enderecoNumero':fields.enderecoNumero,
       'enderecoComplemento':fields.enderecoComplemento,
       'enderecoBairro':bairroApi,
       'enderecoCidade':cidadeApi,
       'enderecoEstado':estadoApi,
       'enderecoPais':fields.enderecoPais,
       'enderecoCep':fields.enderecoCep,
       'observacoes':fields.observacoes,
       'vendedorPadrao': {
         'id':valueAutoId ,
         'nome': valueAutoNome
       },
       'propriedades': fieldsCustom
   
     
     });
 */
    // console.log(teste);
    try {
      setLoading(true);

      const dados = JSON.stringify({
        'ativa': fields.ativa,
        'bloqueada': fields.ativa,
        'tipo': "JURIDICA",
        'nome': fields.nome,
        'apelido': fields.apelido,
        'cnpj': fields.cnpj,
        'inscricaoEstadual': fields.inscricaoEstadual,
        'cpf': fields.cpf,
        'rg': fields.rg,
        'enderecoLogradouro': logradouroApi,
        'enderecoNumero': fields.enderecoNumero,
        'enderecoComplemento': fields.enderecoComplemento,
        'enderecoBairro': bairroApi,
        'enderecoCidade': cidadeApi,
        'enderecoEstado': estadoApi,
        'enderecoPais': fields.enderecoPais,
        'enderecoCep': fields.enderecoCep,
        'observacoes': fields.observacoes,
        'vendedorPadrao': {
          'id': valueAutoId,
          'nome': valueAutoNome
        },
        'propriedades': fieldsCustom

      });
      console.log(dados);
      let formData = new FormData();
      formData.append('dados', dados);

      for (let i = 0; i < files.length; i++) {
        formData.append(`files[${i}]`, files[i])
      }

      const config = {
        headers: { "x-access-token": token, "dados": dados }
      }
      console.log(formData);
      //console.log(getInputValue('nome'));

        const response = await axios.post(
        `${API.clientes}`,
        formData,
        config
      );

      console.log(response);
       loadClients();
      window.location.reload();      

    } catch (err) {
      toast.error(`Falha na criação, verifique seus dados.Erro: ${err.response.data.message}`);
    }
  };

  useEffect(() => {

    const loadprz = async () => {
      const response_prz = await axios.get(
        `${API.getprzpag}`,
        {
          headers: {
            "x-access-token": token
          }
        }
      );
      //const opcoes_prz2 = response_prz.data.data.map(item => console.log(item));
      const opcoes_prz = response_prz.data.data.map(item => { return { value: item.DESCRICAO, label: item.DESCRICAO } });
      console.log(opcoes_prz);
      setprzPagamento(opcoes_prz);


    }
    const loadCarriers = async () => {
      try {
        const responseTransp = await axios.get(`${API.transportadoras}`, {
          headers: {
            'x-access-token': token,
          },
        });



        setautoTransp(
          responseTransp.data.data.map(item => {
            return {
              label: item.APELIDO + '(' + item.CGC + ')',
              value: item.NUMCAD1,
            };
          }),
        );
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
          sessionStorage.clear();
        } else {
          toast.error('Erro ao carregar lista de transportadoras');
        }
      }
    };



    loadprz();
    loadCarriers();

  }, []);

  useEffect(() => {
    async function handleReq() {
      try {
        const response = await axios.get(
          `${API.vendedores}?email=${email}`,
          {
            headers: {
              "x-access-token": token
            }
          }
        );

    //    const list = response.data.data;

        const list = response.data.data.map(item => { return { value: item.NUMCAD1, label: item.NOME } });
        //console.log(opcoes_prz);
        setAuto(list);
        // setprzPagamento(opcoes_prz);




      } catch (err) {
        toast.error("Erro ao listar vendedores");
      }
    }

    handleReq();
  }, []);


  useEffect(() => {
    setfieldsCustom({
      ...fieldsCustom,
      'TRANSPORTADORA': idTransp,
      'REDESPACHO': idTranspRedespacho
    });
  }, [nomeTransp, nomeTranspRedespacho]);

  // Cep
  const handleCep = async e => {
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${e.target.value}/json`
      );
      const {
        logradouro: logradouroApi,
        bairro: bairroApi,
        uf: ufApi,
        localidade: localidadeApi,
        complemento: complementoApi
      } = response.data;

      setLogradouro(logradouroApi);
      setBairro(bairroApi);
      setEstado(ufApi);
      setCidade(localidadeApi);
      setComplemento(complementoApi);
    } catch (err) {
      toast.error("Cep inválido");
    }
  };

  // Selects
  return (
    <div>
      <button className={classes.btnAdd} type="button" onClick={handleOpen}>
        <AddCircleOutlineIcon />Novo Cliente
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>


            <form onSubmit={handleSubmit} enctype="multipart/form-data" >
              <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", paddingBottom: "20px" }}>
                <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                  <input type="hidden" input name="id" />

                  <Grid item xs={12} sm={12} lg={12}>
                    <center><h5>CLIENTE</h5></center>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      select
                      label="Tipo"
                      name="tipo"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required="required"
                      value="JURIDICA"
                      onChange={e => handleRequiretipo(e.target.value)}
                    >
                      <MenuItem value={"JURIDICA"}>JURIDICO</MenuItem>
                    </TextField>

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      id="nome"
                      label="Nome"
                      type="text"
                      onChange={handleChange}
                      value={fields.nome}
                      style={{ width: "100%" }}

                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      id="apelido"
                      label="Apelido"
                      type="text"
                      style={{ width: "100%" }}
                      required="required"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                  </Grid>


                  {tipoRequired ? (
                    <>
                      <Grid item xs={12} sm={12} lg={4}>
                        <InputMask mask="99.999.999/9999-99"
                          onChange={handleChange}
                        >
                          <TextField
                            id="cnpj"
                            label="Cnpj"
                            name="cnpj"
                            type="text"
                            style={{ width: "100%" }}
                            required={(required) ? required : "required"}
                            InputLabelProps={{
                              shrink: true,
                            }}

                          />
                        </InputMask>

                      </Grid>
                      <Grid item xs={12} sm={12} lg={4}>
                        <TextField
                          id="inscricaoEstadual"
                          label="Inscrição Estadual"
                          name="inscricaoEstadual"
                          type="text"
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}

                          required={(required) ? required : "required"}

                        />
                      </Grid>

                    </>
                  ) : (
                      <>
                        <Grid item xs={12} sm={12} lg={4}>
                          <InputMask mask="999.999.999-99"
                            onChange={handleChange}
                          >
                            <TextField
                              label="CPF"
                              name="cpf"
                              id="cpf"
                              type="text"
                              required="required"
                              style={{ width: "100%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}

                            />
                          </InputMask>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={4}>

                          <TextField
                            label="RG"
                            name="rg"
                            id="rg"
                            type="text"
                            required="required"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}

                          />
                        </Grid>

                      </>
                    )}

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      id="enderecoCep"
                      label="CEP"
                      name="enderecoCep"
                      type="text"
                      required="required"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChange}
                      onBlur={handleCep}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Endereço"
                      id="enderecoLogradouro"
                      name="enderecoLogradouro"
                      type="text"
                      required="required"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={logradouroApi}
                      onChange={e => setLogradouro(e.target.value)}

                    />

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Número"
                      id="enderecoNumero"
                      name="enderecoNumero"
                      required="required"
                      type="text"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Complemento"
                      id="enderecoComplemento"
                      name="enderecoComplemento"
                      type="text"
                      style={{ width: "100%" }}

                      InputLabelProps={{
                        shrink: true,
                      }}

                      onChange={handleChange}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Bairro"
                      id="enderecoBairro"
                      name="enderecoBairro"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}

                      required="required"
                      value={bairroApi ? bairroApi : ""}
                      onChange={e => setBairro(e.target.value)}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      id="enderecoCidade"
                      label="Cidade"
                      name="enderecoCidade"
                      type="text"
                      style={{ width: "100%" }}



                      InputLabelProps={{
                        shrink: true,
                      }}
                      required="required"
                      value={cidadeApi ? cidadeApi : ""}
                      onChange={e => setCidade(e.target.value)}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      id="enderecoEstado"
                      label="Estado"
                      name="enderecoEstado"
                      type="text"

                      required="required"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={estadoApi ? estadoApi : ""}
                      onChange={e => setEstado(e.target.value)}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="País"
                      id="enderecoPais"
                      name="enderecoPais"
                      required="required"
                      defaultValue="Brasil"
                      type="text"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <label>Prazo de pagamento</label>
                    <Select
                      options={przPagamento}
                      isClearable={true}
                      onChange={(value) => {

                        setfieldsCustom({
                          ...fieldsCustom,
                          ['PRAZOPAGAMENTO']: value !== null ? value.value : ''
                        });

                      }}
                    />


                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <label>Transportadora</label>
                    <Select
                      options={autoTransp}
                      isClearable={true}
                      onChange={(value) => {

                        setfieldsCustom({
                          ...fieldsCustom,
                          ['TRANSPORTADORA']: value !== null ? value.value : ''
                        });

                      }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <label>Redespacho</label>
                    <Select
                      options={autoTransp}
                      isClearable={true}
                      onChange={(value) => {

                        setfieldsCustom({
                          ...fieldsCustom,
                          ['REDESPACHO']: value !== null ? value.value : ''
                        });

                      }}
                    />

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <label>Vendedor</label>
                    <Select
                      options={auto}
                      isClearable={true}
                      onChange={(value) => {

                        setValueAutoId(value !== null ? value.value : ''
                        );
                        setValueAutoNome(value !== null ? value.label : ''
                        );

                      }}
                    />

                  </Grid>

                </Grid>

              </Box>

              <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", paddingBottom: "20px" }}>
                <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>

                  <Grid item xs={12} sm={12} lg={12}>
                    <center><h5>Contatos </h5></center>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email da empresa"
                      id="EMAIL"
                      name="EMAIL"

                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email pra envio de nota fiscal"
                      id="EMAIL_NFE"
                      name="EMAIL_NFE"
                      required="required"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="TelefoneEmpresa"
                        name="TelefoneEmpresa"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>


                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Responsável pelo financeiro"
                      id="RESPONSAVEL_FINANCEIRO"
                      name="RESPONSAVEL_FINANCEIRO"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email do responsável pelo financeiro"
                      id="EMAIL_FINANCEIRO"
                      name="EMAIL_FINANCEIRO"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="TelefoneFinanceiro"
                        name="TelefoneFinanceiro"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>


                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Nome do comprador"
                      id="COMPRADOR"
                      name="COMPRADOR"
                      required="required"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email do comprador"
                      id="EMAIL_COMPRADOR"
                      name="EMAIL_COMPRADOR"
                      type="text"
                      required="required"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="TelefoneComprador"
                        name="TelefoneComprador"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Nome do estilista"
                      id="NOME_ESTILISTA"
                      name="NOME_ESTILISTA"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email do estilista"
                      id="EMAIL_ESTILISTA"
                      name="EMAIL_ESTILISTA"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="TelefoneEstilista"
                        name="TelefoneEstilista"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Nome responsável por contas a pagar"
                      id="RESPONSAVEL_CONTASAPAGAR"
                      name="RESPONSAVEL_CONTASAPAGAR"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email do responsável por contas a pagar"
                      id="EMAIL_CONTASAPAGAR"
                      name="EMAIL_CONTASAPAGAR"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="TelefoneContasAPagar"
                        name="TelefoneContasAPagar"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>

                </Grid>

              </Box>

              <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", marginBottom: "20px" }}>
                <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <center><h5>Referências comerciais </h5></center>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Empresa"
                      id="Rempresa1"
                      name="Rempresa1"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email"
                      id="RempresaEmail1"
                      name="RempresaEmail1"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="RempresaTelefone1"
                        name="RempresaTelefone1"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Empresa"
                      id="Rempresa2"
                      name="Rempresa2"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email"
                      id="RempresaEmail2"
                      name="RempresaEmail2"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="RempresaTelefone2"
                        name="RempresaTelefone2"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Empresa"
                      id="Rempresa3"
                      name="Rempresa3"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Email"
                      id="RempresaEmail3"
                      name="RempresaEmail3"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="RempresaTelefone3"
                        name="RempresaTelefone3"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>

                  </Grid>
                </Grid>
              </Box>
              <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", marginBottom: "20px" }}>
                <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <center><h5>Referências Bancárias </h5></center>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={2}>
                    <TextField
                      label="Banco"
                      id="banco1"
                      name="banco1"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={2}>
                    <TextField
                      label="Agência"
                      id="agencia1"
                      name="agencia1"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={3}>
                    <TextField
                      label="Conta corrente"
                      id="contacorrente1"
                      name="contacorrente1"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={3}>
                    <TextField
                      label="Contato"
                      id="contatocc1"
                      name="contatocc1"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={2}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="telefonecc1"
                        name="telefonecc1"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={2}>
                    <TextField
                      label="Banco"
                      id="banco2"
                      name="banco2"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={2}>
                    <TextField
                      label="Agência"
                      id="agencia2"
                      name="agencia2"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={3}>
                    <TextField
                      label="Conta corrente"
                      id="contacorrente2"
                      name="contacorrente2"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={3}>
                    <TextField
                      label="Contato"
                      id="contatocc2"
                      name="contatocc2"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={2}>
                    <InputMask mask="(99)999999999"
                      maskPlaceholder=""
                      onChange={handleChangeCustom}>
                      <TextField
                        label="Telefone"
                        id="telefonecc2"
                        name="telefonecc2"
                        type="text"
                        onChange={handleChangeCustom}
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </InputMask>
                  </Grid>

                </Grid>
              </Box>
              <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", marginBottom: "20px" }}>
                <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <center><h5>Particularidades</h5></center>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <label>Como conheceu a empresa, conhece o proprietário, descreva a loja fisicamente, local, estrutura, porte, funcionários, etc</label>
                    <TextField
                      label=""
                      id="particularidades"
                      name="particularidades"
                      multiline
                      rows={5}
                      type="text"

                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true

                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      label="Quantas lojas no varejo?"
                      id="qtd_varejo"
                      name="qtd_varejo"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      label="Quantas lojas no atacado?"
                      id="qtd_atacado"
                      name="qtd_atacado"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <label>Cliente é antenado com as tendências e novidades do mercado, é adequado para trabalhar com os nossos produtos, como se posiciona no mercado, etc?</label>
                    <TextField
                      label=""
                      id="cliente_tendecias"
                      name="cliente_tendecias"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ position: "relative", width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={4}>
                    <TextField
                      label="Qual a sua sugestão de limite?"
                      id="limite"
                      name="limite"
                      type="text"
                      onChange={handleChangeCustom}
                      style={{ position: 'relative' }}

                      InputLabelProps={{
                        shrink: true,
                        position: 'relative'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>

                    <TextField
                      select
                      label="Como você classificaria o cliente?"
                      name="classificacao"
                      id="classificacao"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required="required"

                      onChange={e => handleRequireClassificacao(e.target.value)}
                    >
                      <MenuItem value={"OTIMO"}>ÓTIMO</MenuItem>
                      <MenuItem value={"BOM"}>BOM</MenuItem>
                      <MenuItem value={"REGULAR"}>REGULAR</MenuItem>
                    </TextField>
                  </Grid>


                </Grid>
              </Box>
              <Box boxShadow={3} style={{ background: "#ffffff", margin: "10px", top: "15px", paddingTop: "20px", paddingBottom: "20px", marginBottom: "20px" }}>
                <Grid style={{ paddingRight: "20px", paddingLeft: "20px", paddingBottom: "20px" }} container spacing={2}>

                  <Grid item xs={12} sm={12} lg={12}>
                    <center><h5>Arquivos </h5></center>
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="content" style={{ height: "auto", margin: "10px", paddingBottom: "20px" }}>
                      <Dropzone edit={false} files_svd={files_saved} parentFiles={setFiles} />
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <ButtonStyled variant="contained" color="primary">
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {loading && <span>Cadastrando</span>}
                {!loading && <span>Cadastrar</span>}
              </ButtonStyled>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
