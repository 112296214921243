/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */

import Avatar from '@material-ui/core/Avatar';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import { connect } from 'react-redux';
import DataTable from 'components/Table/Table.js';
import Carrinho from 'components/CarrinhoModal';
import FinalizaPedido from './FinalizaPedido';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Pdf from '../../components/Produtos/PDF';
import PdfColecao from '../../components/Produtos/PDFCOLECAO';
import ImagesProducts from '../../components/Produtos/Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import {
  Form,
  ButtonStyled,
  Input,
  ContainerSearch,
  firstline,
} from './styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import { FormAuto } from './stylesFinaliza';
import { API } from '../../config/api';
import { FullscreenExit } from '@material-ui/icons';
import Autocomplete from 'react-autocomplete';
import Paper from '@material-ui/core/Paper';
import { TablePagination, Typography, Divider } from '@material-ui/core';

// Função para criar OBJ dos produtos
function createData(
  exc,
  colecao,
  img,
  ITEM_CODIGO,
  ITEM_NOME,
  ITEM_GRADE,
  EMPRESA,
  PE_OU_PROG,
  ITEM_SALDO,
  inputQuantidade,
  inputValor,
) {
  return {
    exc,
    colecao,
    img,
    ITEM_CODIGO,
    ITEM_NOME,
    ITEM_GRADE,
    EMPRESA,
    PE_OU_PROG,
    ITEM_SALDO,
    inputQuantidade,
    inputValor,
  };
}

const rowHead = [
  {
    title: 'Código',
    field: 'ITEM_CODIGO',
    headerStyle: {
      width: 15,
      maxWidth: 20,
      textAlign: 'center',
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      verticalAlign: 'middle',
      height: '100%',
      margin: '1px',
      padding: '1px',
    },
  },
  {
    title: 'Nome',
    field: 'ITEM_NOME',
    headerStyle: {
      width: 45,
      maxWidth: 60,
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
      margin: '1px',
      padding: '1px',
    },
  },
  {
    title: 'Grade',
    field: 'ITEM_GRADE',
    headerStyle: {
      width: 25,
      maxWidth: 30,
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Disp.',
    field: 'ITEM_SALDO',
    headerStyle: {
      textAlign: 'right',
      width: 15,
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
      textAlign: 'right',
    },
  },

  {
    title: 'Qtd',
    field: 'inputQuantidade',
    headerStyle: {
      width: 12,
      minWidth: 30,
      maxWidth: 30,
      textAlign: 'center',
    },
    cellStyle: {
      fontSize: '15px',
      margin: '1px',
      padding: '1px',

      whiteSpace: 'nowrap',
    },
  },

  {
    title: 'Vl Un',
    field: 'inputValor',
    headerStyle: {
      textAlign: 'center',
      width: 25,
      minWidth: 100,
      maxWidth: 100,
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
    },
  },
  {
    title: 'Prog.',
    field: 'PE_OU_PROG',
    headerStyle: {
      width: 25,
      maxWidth: 25,
      minWidth: 25,
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
    },
  },

  {
    title: 'Empresa',
    field: 'EMPRESA',
    headerStyle: {
      width: 25,
      maxWidth: 30,
    },
    cellStyle: {
      fontSize: '15px',
      whiteSpace: 'nowrap',
    },
  },
  {
    field: 'exc',
    headerStyle: {
      width: 15,
      maxWidth: 15,
      margin: '3px',
      padding: '3px',
    },
    cellStyle: {
      fontSize: '3px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      margin: '10px',
      padding: '10px',
    },
  },
  {
    field: 'colecao',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      margin: '1px',
      padding: '1px',
    },
    cellStyle: {
      fontSize: '3px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      margin: '10px',
      padding: '10px',
    },
  },
  {
    field: 'img',
    headerStyle: {
      width: 10,
      maxWidth: 10,
      margin: '1px',
      padding: '1px',
    },
    cellStyle: {
      fontSize: '3px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      margin: '10px',
      padding: '10px',
    },
  },
];

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedDiv = ({ children, label }) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      style={{ whiteSpace: 'nowrap', marginRight: '30px', marginTop: '10px' }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};

function formatDecimal(numero) {
  return numero.replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
}

function Clientes() {
  const [isConfirmRequest, setIsConfirmRequest] = useState(false);
  const [isConfirmRequestEdit, setIsConfirmRequestEdit] = useState(false);
  const [isValueDefaultUnitarioOpen, setIsValueDefaultUnitarioOpen] = useState(
    false,
  );
  const [open, setOpen] = React.useState(false);
  const [lista, setLista] = useState([]);
  const [listCart, setListCart] = useState([]);
  const [listaUpdate, setListaUpdate] = useState([]);
  const token = sessionStorage.getItem('token');
  const email = sessionStorage.getItem('email');
  const isRequest = listCart.length === 0;
  const [ConfirmPedido, setConfirmPedido] = useState(false);
  const [autoTransp, setTransp] = useState([]);
  const [autoCliente, setAutoCliente] = useState([]);
  const [autoVendedor, setAutoVendedor] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [books, setBooks] = useState([]);
  //const [book_escolhidoID, setBookEscolhidoID] = useState("");
  //const [book_escolhidoNome, setBookEscolhidoNome] = useState("");
  const [totalEstoque, setTotalEstoque] = useState(0);
  const [totalEstoqueEsc, setTotalEstoqueEsc] = useState(0);

  const [valorDesconto, setValorDesconto] = useState(0);
  const [checkboxsete, setCheckboxsete] = useState(false);
  const [checkboxdez, setCheckboxdez] = useState(false);

  var valor_default_unitario = sessionStorage.getItem('valor_unitario_default');

  const [valor_unitario_default, setValor_unitario_default] = useState(
    valor_unitario_default ? valor_unitario_default : '0,00',
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsConfirmRequestEdit(false);
  };

  function updatelist(lista) {
    setLista([]);
    setLista(lista); //Atualizo a lista
  }

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'scroll',
    },
  }));

  const classes = useStyles();

const parametros = useParams();

  useEffect(() => {
    const loadCarriers = async () => {
      try {
        const responseTransp = await axios.get(`${API.transportadoras}`, {
          headers: {
            'x-access-token': token,
          },
        });

        setTransp(
          responseTransp.data.data.map(item => {
            return {
              name: item.APELIDO + '(' + item.CGC + ')',
              id: item.NUMCAD1,
            };
          }),
        );
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error('Sua sessão expirou, favor efetuar o login');
          sessionStorage.clear();
        } else {
          toast.error('Erro ao carregar lista de transportadoras');
        }
      }
    };

    /*  const loadBooks = async () => {
      try {
        const responseBooks = await axios.get(`${API.books}`, {
          headers: {
            "x-access-token": token,
          },
        });

        setBooks("");
        setBooks(
          responseBooks.data.data.map((item) => {
            return {
              id_book: item.ID_UUID,
              descricao: item.DESCRICAO,
            };
          })
        );
      } catch (err) {
        if (err.response && err.response.status === 402) {
          //token expirado
          toast.error("Sua sessão expirou, favor efetuar o login");
          sessionStorage.clear();
        } else {
          toast.error("Erro ao carregar lista de books");
        }
      }
    };*/

    loadCarriers();
    //loadBooks();
    getCartEndpoint();
  }, []);

  async function getCartEndpoint() {
    try {
      const response = await axios.get(`${API.carrinho}?email=${email}`, {
        headers: {
          'x-access-token': token,
        },
      });
      var newarray = [];

      response.data.data.map(item => {
        item.map(newitem => {
          const {
            ID,
            ITEM_ID,
            ITEM_NOME,
            ITEM_GRADE,
            EMPRESA_APELIDO,
            PE_OU_PROG,
            ITEM_SALDO,
            ITEM_UNIDADE,
            ITEM_VALOR_UNITARIO,
          } = newitem;
          newarray.push(newitem);
        });
      });
      setListCart(newarray);

      const totalEstoq = newarray
        ? newarray.reduce(
            (total, newarray) => total + Number(newarray.ITEM_SALDO),
            0,
          )
        : 0;
      //   setTotalEstoque(totalEstoq);

      const totalEstoqEscolhido = newarray
        ? newarray.reduce((total, newarray) => total + newarray.QUANTIDADE, 0)
        : 0;
      setTotalEstoqueEsc(totalEstoqEscolhido);
    } catch (error) {
      if (error.response && error.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login');
        sessionStorage.clear();
      }
      return false;
    }
  }
  //desmembrando o array

  const addToCartAmount = async (data, value) => {
    if (data.ITEM_VALOR_UNITARIO_ESCOLHIDO > 0.0) {
      if (
        data.ITEM_VALOR_UNITARIO_ESCOLHIDO <
        data.ITEM_VALOR_UNITARIO * 0.845
      ) {
        data.ITEM_VALOR_UNITARIO_ESCOLHIDO = data.ITEM_VALOR_UNITARIO;
        toast('O valor informado está abaixo do mínimo permitido.', {
          autoClose: 5000,
        });
      } else {
        const index =
          listCart.length > 0
            ? listCart.findIndex(i => i.ITEM_CODIGO === data.ITEM_CODIGO)
            : -1;
        const itemBalance = await checkItemBalance(data);

        // console.log(">>> index ......:", index);
        // console.log(">>> value ......:", value);
        // console.log(">>> itemBalance.:", itemBalance);

        const amountOld = data.QUANTIDADE;
        data.QUANTIDADE = value;
        data.ITEM_SALDO = (itemBalance - value).toFixed(2);

        if (index === -1) {
          if (value > 0) {
            setListCart([...listCart, data]);
          }
        } else {
          if (index > -1 && parseFloat(value) === 0.0) {
            data.ITEM_SALDO = itemBalance;
            setListCart(listCart.filter(i => i.ITEM_ID !== data.ITEM_CODIGO));
          } else {
            listCart[index].QUANTIDADE = data.QUANTIDADE;
            listCart[index].ITEM_SALDO = data.ITEM_SALDO;
            if (value > 0) {
              setListCart(listCart);
            }
          }
        }
     //   console.log(listCart);
        const dataSendCart = {
          email: email,
          item_id: data.ITEM_ID,
          codigo: data.ITEM_CODIGO,
          item_nome: data.ITEM_NOME,
          item_grade: data.ITEM_GRADE,
          item_unidade: data.ITEM_UNIDADE,
          empresa_id_erp: data.EMPRESA_ID,
          empresa_apelido: data.EMPRESA_APELIDO,
          data_programacao: data.ITEM_PREVISAO,
          quantidade: value,
          lista_preco_id: '1',
          valor_unitario: data.ITEM_VALOR_UNITARIO_ESCOLHIDO,
          valor_unitario_padrao: data.ITEM_VALOR_UNITARIO,
          tipo_venda: data.PE_OU_PROG,
          programacao_numero: data.PROGRAMACAO_ID,
          programacao_item_id: data.ITEM_ID,
        };
        // console.log("22222222222222222222");
        if (!isConfirmRequest && parseFloat(value) > 0) {
          const response = await insertItemCart(dataSendCart, amountOld);
       //   console.log(listCart);
          const totalEstoq = listCart
            ? listCart.reduce(
                (total, listCart) => total + Number(listCart.ITEM_SALDO),
                0,
              )
            : 0;

          getCartEndpoint();
        }
      }
      data.QUANTIDADE = value;
      const itemBalance = await checkItemBalance(data);
      data.ITEM_SALDO = (itemBalance - value).toFixed(2);
    }
  };

  const addToCartPrice = async (data, value) => {
    if (data.QUANTIDADE > 0) {
      const index =
        listCart.length > 0
          ? listCart.findIndex(i => i.ITEM_ID === data.ITEM_CODIGO)
          : -1;

      //const valueValid = data.ITEM_VALOR_UNITARIO_ESCOLHIDO * 0.79;
      /*   
                  console.log(">>> index .......:", index);
                  console.log(">>> value Item ..:", data.ITEM_VALOR_UNITARIO);
                  console.log(">>> value Digita :", value);
                  console.log("");
                  console.log(">>> data ........:", data);
                  console.log(">>> listCart ....:", listCart);
                 */

      if (index === -1) {
        if (value > 0) {
          data.ITEM_VALOR_UNITARIO_ESCOLHIDO = value;
          const dataSendCart = {
            email: email,
            item_id: data.ITEM_ID,
            codigo: data.ITEM_CODIGO,
            item_nome: data.ITEM_NOME,
            item_grade: data.ITEM_GRADE,
            item_unidade: data.ITEM_UNIDADE,
            empresa_id_erp: data.EMPRESA_ID,
            empresa_apelido: data.EMPRESA_APELIDO,
            data_programacao: data.ITEM_PREVISAO,
            quantidade: data.QUANTIDADE,
            lista_preco_id: '1',
            valor_unitario: value,
            valor_unitario_padrao: data.ITEM_VALOR_UNITARIO,
            tipo_venda: data.PE_OU_PROG,
            programacao_numero: data.PROGRAMACAO_ID,
            programacao_item_id: data.ITEM_ID,
          };

          if (
            !isConfirmRequest &&
            parseFloat(value) > 0.0 &&
            data.QUANTIDADE > 0
          ) {
            await insertItemCart(dataSendCart, data.QUANTIDADE);
            getCartEndpoint();
          }
        }
        //   if (value !== data.ITEM_VALOR_UNITARIO_ESCOLHIDO) setListCart([...listCart, data]);
      } else {
        if (value > 0) {
          if (value !== data.ITEM_VALOR_UNITARIO) {
            listCart[index].ITEM_VALOR_UNITARIO_ESCOLHIDO = value;
            data.ITEM_VALOR_UNITARIO_ESCOLHIDO = value;

            const dataSendCart = {
              email: email,
              item_id: data.ITEM_ID,
              codigo: data.ITEM_CODIGO,
              item_nome: data.ITEM_NOME,
              item_grade: data.ITEM_GRADE,
              item_unidade: data.ITEM_UNIDADE,
              empresa_id_erp: data.EMPRESA_ID,
              empresa_apelido: data.EMPRESA_APELIDO,
              data_programacao: data.ITEM_PREVISAO,
              quantidade: data.QUANTIDADE,
              lista_preco_id: '1',
              valor_unitario: value,
              valor_unitario_padrao: data.ITEM_VALOR_UNITARIO,
              tipo_venda: data.PE_OU_PROG,
              programacao_numero: data.PROGRAMACAO_ID,
              programacao_item_id: data.ITEM_ID,
            };

            if (
              !isConfirmRequest &&
              parseFloat(value) > 0 &&
              data.QUANTIDADE > 0
            ) {
              const response = await insertItemCart(
                dataSendCart,
                data.QUANTIDADE,
              );
              getCartEndpoint();
            }
          }
        } else {
          toast('O valor informado está abaixo do mínimo permitido.', {
            autoClose: 5000,
          });
        }
      }
    }
  };

  const handleSearch = async e => {
    try {
      setLoading(true);


      if (e.produto !== '') {
        const response = await axios.get(
          `${API.produtos}?email=${email}&nome=${e.produto}&prevenda=${
            e.prevenda == true ? 'S' : 'N'
          }`,
          {
            headers: {
              'x-access-token': token,
            },
          },
        );
        const list = response.data.data;
        setLista([]);
        setLista(list);

        const totalEstoq = list
          ? list.reduce((total, list) => total + Number(list.ITEM_SALDO), 0)
          : 0;
        setTotalEstoque(totalEstoq);

        if (list && list[0] && list[0].READONLY == 'true') {
          toast.error('Produto não localizado, revise sua pesquisa.');
        }
      } else {
        toast('Favor informar um produto para pesquisa', { autoClose: 5000 });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 402) {
        //token expirado
        toast.error('Sua sessão expirou, favor efetuar o login');
        sessionStorage.clear();
      } else if (error.response && error.response.status === 404) {
        //token expirado
        toast.error('Produto não encontrado.');
        setLista([]);
      } else {
        //
      }
    }
  };

  

  const checkItemBalance = async item => {
    try {
      const response = await axios.get(
        `${API.consultasaldo}?email=${email}&item_id=${item.ITEM_ID}&codigo=${item.ITEM_CODIGO}&empresa_id=${item.EMPRESA_ID}&tipo_venda=${item.PE_OU_PROG}&programacao_numero=${item.PROGRAMACAO_ID}`,
        {
          headers: {
            'x-access-token': token,
          },
        },
      );
      return response.data ? response.data.data.saldo : -1;
    } catch (err) {
      toast.error('Ocorreu algum erro');
    }
  };

  const insertItemCart = async item => {
    try {
      const response = await axios.post(`${API.adicionaaocarrinho}`, item, {
        headers: {
          'x-access-token': token,
        },
      });
      return response.data.data;
    } catch (err) {
      // console.log(err);
      toast.error('Erro ao inserir.');
      return 'erro';
    }
  };

  // let mask = function(rawValue) {
  //   let array = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  //   let value = rawValue;
  //   let teste = String(350);
  //   let resultado = teste.substr(0, 1) + "." + teste.substr(1, 2);

  //   console.log("ValorMask", value);

  //   if (value.length == 3) {
  //     let valorTransfor = String(value) + 0;
  //     console.log("teste", valorTransfor);
  //   }
  //   switch (value.length) {
  //     case 3:
  //       array = [/[0-9]/, ".", /\d/, /\d/];
  //       break;
  //     case 5:
  //       array = [/[0-9]/, /\d/, ".", /\d/, /\d/];
  //       break;
  //     case 6:
  //       array = [/[0-9]/, /\d/, /\d/, ".", /\d/, /\d/];
  //       break;
  //     case 7:
  //       array = [/[0-9]/, /\d/, /\d/, /\d/, ".", /\d/, /\d/];
  //       break;
  //     case 8:
  //       array = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/];
  //   }

  //   return array;
  // };
  const onSubmit = dados => {
    const rowsList = lista
      ? lista.map(item => {
          const {
            ITEM_ID,
            READONLY,
            ITEM_CODIGO,
            ITEM_NOME,
            ITEM_GRADE,
            EMPRESA_APELIDO,
            PE_OU_PROG,
            ITEM_SALDO,
            ITEM_UNIDADE,
            ITEM_VALOR_UNITARIO,
            ITEM_VALOR_UNITARIO_ESCOLHIDO,
            QUANTIDADE,
          } = item;
          let qtn = QUANTIDADE;
          if (item.QUANTIDADE < 0.1) {
            item.ITEM_VALOR_UNITARIO_ESCOLHIDO = dados.valor_default_unitario;
            //   item.ITEM_VALOR_UNITARIO=dados.valor_default_unitario;
          }
          //item.ITEM_VALOR_UNITARIO.value=Number(dados.valor_default_unitario);
        })
      : '';

    setLista([]);
    setLista(lista);
    setOpen(false); //Fecho o modal
  };

  const { handleSubmit, register } = useForm();


  
  useEffect(() => {
if(parametros.name_prod){
        var e ={}
        e.produto=parametros.name_prod
        e.prevenda=true;
        handleSubmit(handleSearch(e));
}

  }, [parametros.name_prod]);


  const rowsList = lista
    ? lista.map(item => {
        const {
          READONLY,
          ITEM_ID,
          ITEM_CODIGO,
          ITEM_NOME,
          ITEM_GRADE,
          MESTRE_CODIGO,
          MESTRE_ID,
          EMPRESA_APELIDO,
          PE_OU_PROG,
          ITEM_SALDO,
          ITEM_UNIDADE,
          ITEM_VALOR_UNITARIO,
          ITEM_VALOR_UNITARIO_ESCOLHIDO,
          QUANTIDADE,
        } = item;

        let qtn = QUANTIDADE;
        if (qtn > 0) {
          if (!String(qtn).match(/.00/) && String(qtn).length == 5) {
            if (qtn >= 100) {
              qtn += '0';
            }
          }
        }
        let mask = function (rawValue) {};
        const row = createData(
          <Pdf num={ITEM_ID} />,
          <PdfColecao num={MESTRE_CODIGO} />,
          <ImagesProducts
            num={ITEM_CODIGO}
            mestre_id={MESTRE_ID}
            mestre_codigo={MESTRE_CODIGO}
          />,
          ITEM_CODIGO,
          ITEM_NOME,
          ITEM_GRADE,
          EMPRESA_APELIDO,
          PE_OU_PROG,
          formatDecimal(ITEM_SALDO) + ' ' + ITEM_UNIDADE,
          READONLY == 'false' ? (
            <Input
              type="number"
              name="quantidade"
              ref={register}
              defaultValue={QUANTIDADE}
              onFocus={e => e.target.select()}
              onWheel={e => e.target.blur()}
              onBlur={e =>
                parseFloat(e.target.value) > 0
                  ? addToCartAmount(item, e.target.value)
                  : ''
              }
              style={{
                height: '100%',
                minWidth: '4rem',
                maxWidth: '5rem',
                textAlign: 'right',
              }}
              //readOnly={READONLY=='false'?false:true}
            />
          ) : (
            '-'
          ),
          READONLY == 'false' ? (
            <Input
              type="number"
              name="valorUnitario"
              id="valorUnitario"
              ref={register}
              defaultValue={ITEM_VALOR_UNITARIO_ESCOLHIDO}
              onWheel={e => e.target.blur()}
              onClick={e => {
                if (e.target.value < 0.845 * ITEM_VALOR_UNITARIO) {
                  e.target.value = ITEM_VALOR_UNITARIO;
                  toast('O valor informado está abaixo do mínimo permitido.', {
                    autoClose: 5000,
                  });
                }
              }}
              onBlur={e => {
                if (e.target.value < 0.845 * ITEM_VALOR_UNITARIO) {
                  e.target.value = ITEM_VALOR_UNITARIO;
                  toast('O valor informado está abaixo do mínimo permitido.', {
                    autoClose: 5000,
                  });
                } else {
                  addToCartPrice(item, e.target.value);
                }
              }}
              onFocus={e => e.target.select()}
              style={{
                height: '100%',
                minWidth: '6rem',
                maxWidth: '6rem',
                textAlign: 'right',
              }}
              //readOnly={READONLY=='false'?false:true}
            />
          ) : (
            '-'
          ),
          ITEM_VALOR_UNITARIO,
        );

        return row;
      })
    : [{ error: 'Não encontrado' }];

  async function cancelRequest() {
    setListCart([]);
    setLista([]);
    setIsConfirmRequest(false);
  }

  function confirmaPedido(e) {
    e.preventDefault();

    setConfirmPedido(true);
    setIsConfirmRequest(true);
  }
  // Inicia o processo de seleção e Produtos (quantidade e valor)
  return (
    <>
      <Form onSubmit={handleSubmit(handleSearch)}>
        {!isConfirmRequest && (
          <>
            <div
              className="firstline"
              style={{
                background: 'white',
                marginTop: '0px',
                flexWrap: 'wrap',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <label>Produto: </label>
              <input
                name="produto"
                type="text"
                defaultValue={parametros.name_prod?parametros.name_prod:''}
                ref={register}
                style={{
                  width: '8rem',
                  minWidth: '8rem',
                  height: 'calc(2em + 0.75rem + 2px)',
                  fontSize: '12px',
                  marginRight: '5px',
                }}
              />

              {/*}            <label>Book: </label>
                        <select
                          id="book"
                          name="book"
                          placeholder="Escolha o Book"
                          style={{
                            minWidth: '10rem',
                            height: 'calc(2em + 0.75rem + 2px)',
                            fontSize: '12px',
                            marginRight: '5px'
                          }}
                          ref={register}
                          //value={frete}
                          //defaultValue={frete}
                          //onChange={e => (setFrete(e.target.value))}
                        >
                        
                          <option value=""></option>
                        { books ? books.map(item=>{

                        return  <option value={item.id_book}>{item.descricao}</option>;
                          
                          }):' <option value=""></option>'
                        }
                         
                        </select>
                     
              <label>Book</label>
              <Autocomplete
                renderInput={(props) => (
                  <input
                    label="Escolha o Book"
                    style={{
                      width: "auto",
                      minWidth: "8rem",
                      height: "calc(2em + 0.75rem + 2px)",
                      fontSize: "9px",
                      marginRight: "5px",
                    }}
                    {...props}
                    autoComplete={false}
                  />
                )}
                items={books ? books : []}
                wrapperStyle={{
                  fontSize: "10px",
                  position: "relative",
                  zIndex: "20",
                  display: "inline-block",
                }}
                shouldItemRender={(item, value) =>
                  item.descricao
                    ? item.descricao
                        .toLowerCase()
                        .indexOf(value.toLowerCase()) > -1
                    : ""
                }
                getItemValue={(item) => {
                  setBookEscolhidoID(item.id_book);
                  return item.descricao;
                }}
                renderItem={(item, isHighlighted) => (
                  <div
                    key={item.id_book}
                    style={{
                      fontSize: "10px",
                      background: isHighlighted ? "lightgray" : "white",
                      width: "100%",
                    }}
                  >
                    <span> {item.descricao}</span>
                  </div>
                )}
                value={book_escolhidoNome}
                onChange={(e) => {
                  if (e.target.value.length == 0) {
                    setBookEscolhidoID(null);
                  }
                  setBookEscolhidoNome(e.target.value);
                }}
                onSelect={(val) => {
                  setBookEscolhidoNome(val);
                }}
              />
            */}
              <label>Pré-Venda: </label>

              <input
                style={{
                  minWidth: '1rem',
                  width: '50px',
                  verticalAlign: 'middle',
                  height: 'calc(2em + 0.75rem + 2px)',
                  fontSize: '12px',
                  marginRight: '5px',
                }}
                type="checkbox"
                name="prevenda"
                ref={register}
                defaultChecked={true}
              />

              <ButtonStyled>
                {loading && (
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: '5px' }}
                  />
                )}
                {loading && <span>Buscando Produtos</span>}
                {!loading && <span>Pesquisar</span>}
              </ButtonStyled>

              {!isRequest && !isConfirmRequest && (
                <>
                  {' '}
                  <button
                    style={{
                      marginTop: 0,
                      marginLeft: 0,
                      marginRight: 30,
                      padding: 10,
                      width: 200,
                      border: 0,
                      backgroundColor: '#00acc1',
                      borderRadius: 5,
                      color: '#fff',
                      fontSize: 16,
                      fontWeight: 'bold',
                      transition: '0.4s',
                      cursor: 'pointer',
                    }}
                    disabled={isRequest}
                    onClick={confirmaPedido}
                  >
                    Finalizar Pedido
                  </button>
                </>
              )}

              {!isRequest && !isConfirmRequest && (
                <div className="inputcheck">
                  <OutlinedDiv label="Pré venda">
                    <Grid container justify="center" alignItems="center">
                      <Carrinho
                        itemCart={
                          listCart != undefined
                            ? listCart.filter(item => {
                                return (
                                  item.TIPO_VENDA != undefined &&
                                  item.TIPO_VENDA.includes('P.V')
                                );
                              })
                            : ''
                        }
                      />
                    </Grid>
                  </OutlinedDiv>
                  <OutlinedDiv label="Demais itens">
                    <Grid container justify="center" alignItems="center">
                      <Carrinho
                        itemCart={
                          listCart != undefined
                            ? listCart.filter(item => {
                                return (
                                  item.TIPO_VENDA != undefined &&
                                  !item.TIPO_VENDA.includes('P.V')
                                );
                              })
                            : ''
                        }
                      />
                    </Grid>
                  </OutlinedDiv>
                </div>
              )}
            </div>
          </>
        )}
      </Form>
      {ConfirmPedido && (
        <>
          <FormAuto>
            <FinalizaPedido
              itemCart={listCart}
              Transp={autoTransp}
              Clients={autoCliente}
              Vendedores={autoVendedor}
            />
          </FormAuto>
        </>
      )}
      {!isConfirmRequest && (
        <>
          {isConfirmRequestEdit && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  className={classes.paper}
                  style={{ maxWidth: 'min-content' }}
                >
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} lg={12}>
                        <div className="inputdecimal">
                          <Grid item xs={12} sm={12} lg={12}>
                            <div className="input">
                              <label>Valor unitário padrão</label>
                              <input
                                name="valor_default_unitario"
                                type="number"
                                value={valorDesconto}
                                ref={register}
                                onChange={value => {
                                  setValorDesconto(value.value);
                                }}
                                step={0.01}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12}>
                            <OutlinedDiv label="Conceder os descontos automáticos abaixo">
                              <label>7%: </label>

                              <input
                                style={{
                                  minWidth: '1rem',
                                  width: '50px',
                                  margin: '0px',
                                  verticalAlign: 'middle',
                                  //      height: 'calc(2em + 0.75rem + 2px)',
                                  fontSize: '12px',
                                  //    marginRight: '5px',
                                }}
                                type="checkbox"
                                name="seteporcento"
                                ref={register}
                                onChange={e => {
                                  setCheckboxsete(!checkboxsete);
                                  setCheckboxdez(false);
                                  if (!checkboxsete) {
                                    setValorDesconto(
                                      (valor_unitario_default * 0.93).toFixed(
                                        2,
                                      ),
                                    );
                                  } else {
                                    setValorDesconto(valor_unitario_default);
                                  }
                                }}
                                checked={checkboxsete}
                                defaultChecked={false}
                              />
                              <label>10%: </label>

                              <input
                                style={{
                                  minWidth: '1rem',
                                  width: '50px',
                                  margin: '0px',
                                  verticalAlign: 'middle',
                                  //height: 'calc(2em + 0.75rem + 2px)',
                                  fontSize: '12px',
                                  //  marginRight: '5px',
                                }}
                                type="checkbox"
                                name="dezporcento"
                                ref={register}
                                onChange={e => {
                                  setCheckboxsete(false);
                                  setCheckboxdez(!checkboxdez);
                                  if (!checkboxdez) {
                                    setValorDesconto(
                                      (valor_unitario_default * 0.9).toFixed(2),
                                    );
                                  } else {
                                    setValorDesconto(valor_unitario_default);
                                  }
                                }}
                                checked={checkboxdez}
                                defaultChecked={false}
                              />
                            </OutlinedDiv>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <ButtonStyled variant="contained" color="primary">
                          Alterar valor unitário
                        </ButtonStyled>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={12}>
                        <span
                          style={{
                            overflowWrap: 'break-word',
                            fontSize: '9px',
                            textAlign: 'right',
                          }}
                        >
                          *A alteração do valor gerará impacto sobre todos os
                          produtos pesquisados, desconsiderando o filtro
                          opcional da tabela (Filtrar)
                        </span>
                      </Grid>
                    </Grid>
                  </Form>
                </div>
              </Fade>
            </Modal>
          )}
          <DataTable
            rows={rowsList}
            rowHead={rowHead}
            title={'Produtos'}
            sort={true}
            titleNoData={'Pesquise os produtos'}
            filter={true}
            maxHeight="76"
            addAction={[
              {
                icon: 'list',
                onClick: () => {
                  setIsConfirmRequestEdit(!isConfirmRequestEdit);
                  setOpen(true);
               //   console.log(rowsList[0]);
                  const value = rowsList[0]
                    ? rowsList.slice(0, 1)[0].inputValor.props.defaultValue
                    : 0;
                  setValor_unitario_default(value);
                  setValorDesconto(value);
                },
                isFreeAction: true,
                tooltip: 'Alterar valor unitário',
              },
            ]}
            components={{
              Pagination: props => (
                <>
                  <Grid container spacing={0} style={{ alignItems: 'end' }}>
                    <Grid item xs={10} lg={11} md={11}>
                      <Grid container spacing={0} style={{ width: '100%' }}>
                        <Grid
                          className="index"
                          item
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'end',
                          }}
                        >
                          <Typography variant="subtitle2">
                            Total escolhido:
                          </Typography>
                        </Grid>
                        <Grid
                          className="index"
                          item
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'end',
                          }}
                        >
                          <Typography variant="subtitle2">Estoque:</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} lg={1} md={1}>
                      <Grid container spacing={0} style={{ width: '100%' }}>
                        <Grid
                          className="index"
                          item
                          sm={12}
                          lg={12}
                          md={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            textAlign: 'end',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {totalEstoqueEsc.toLocaleString('pt-BR', {
                              style: 'decimal',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                            }) + ' m '}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        className="index"
                        item
                        sm={12}
                        lg={12}
                        md={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          textAlign: 'end',
                        }}
                      >
                        <Typography variant="subtitle2">
                          {totalEstoque
                            ? totalEstoque.toLocaleString('pt-BR', {
                                style: 'decimal',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                              }) + ' m '
                            : '0,00 m'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <TablePagination {...props} />
                </>
              ),
            }}
          />
        </>
      )}

      <ToastContainer />
    </>
  );
}

export default connect()(Clientes);
/*
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Paper elevation={9} square>
                <span>
                  <b>Total escolhido:</b>
                </span>{" "}
                {totalEstoqueEsc.toLocaleString("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                }) + " m "}
                <span>
                  <b> Estoque Disponível: </b>
                </span>{" "}
                {totalEstoque
                  ? totalEstoque.toLocaleString("pt-BR", {
                      style: "decimal",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })
                  : "0,00"}
              </Paper>
          </div>
 */
