import axios from "axios";

const api = axios.create({
  //baseURL: "http://localhost:3003/api/v1",
  //baseURL: "http://lucin-apihomologacao.salesbreath.com.br:3004/api/v1",
  baseURL: "https://lucin-api.salesbreath.com.br/api/v1",
  headers: { "Content-Type": "application/json" }
});

export default api;
