
import React from "react";
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import axios from "axios";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button } from "./styles";
import { API } from "../../../config/api"


export default function PdfColecao({ num }) {
  const token = sessionStorage.getItem("token");
  const email = sessionStorage.getItem("email");
  const handlePdf = async () => {
    try {
      toast.success("Pesquisando...");
      //window.open(url, "_blank", "noreferrer");

      window.open(`${API.imagens_colecao}/${num}/${num}.pdf`, "_blank", "noreferrer");


      //   window.open(`${API.imagens_colecao}/${num}/${num}.pdf`);
    } catch (err) {
      toast.error("Não foi possível encontrar o link");
    }
  };
  return (
    <Button type="button" onClick={(e) => handlePdf(e)}>

      <CameraEnhanceIcon />
    </Button>
  );
}

PdfColecao.propTypes = {
  num: PropTypes.number.isRequired
};
